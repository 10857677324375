import { currentDate } from './common'
import { useToasterStore } from '@/stores/toaster'

const { state: toasterStoreState } = useToasterStore()
/* eslint-disable indent */
export const getTemplateType = (value: number) => {
  switch (value) {
    case 1:
      return 'SMS'
    case 2:
      return 'WhatsApp'

    default:
      return ''
  }
}

export const getCategoryType = (value: number) => {
  switch (value) {
    case 1:
      return 'Static'
    case 2:
      return 'Variable'
    case 3:
      return 'System Generated'

    default:
      return ''
  }
}

export const getSubCategory = (value: number) => {
  switch (value) {
    case 1:
      return 'Loader'
    case 2:
      return 'Trucker'
    case 3:
      return 'Offers'
    case 4:
      return 'Loads'
    case 5:
      return 'Confirmation'
    default:
      return ''
  }
}

export const passSubCategory = (value: string) => {
  switch (value) {
    case 'loaders':
      return 1
    case 'truckers':
      return 2
    case 'loads':
      return 3
    case 'offers':
      return 4
    case 'offerSuggestions':
      return 3
    case 'loadSuggestions':
      return 3
    case 'confirmation':
      return 5
    default:
      return ''
  }
}

export const passTemplateType = (value: string) => {
  switch (value) {
    case 'sms':
      return 1
    case 'whatsapp':
      return 2

    default:
      return ''
  }
}

export const downloadAndViewFile = (buffer: any, fileType: string | null, prefix: string) => {
  if (buffer) {
    const blobType = fileType === 'excel'
      ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      : 'application/pdf'

    const url = window.URL.createObjectURL(new Blob([buffer], { type: blobType }))
    const link = document.createElement('a')

    link.href = url
    link.setAttribute('download', `${prefix}-${currentDate.unix()}.${fileType === 'excel' ? 'xlsx' : 'pdf'}`)
    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }
  else {
    toasterStoreState.displaySnackbar('Something went wrong while downloading the document. Please try again later.', 'error')
  }
}

export const downloadOrPreviewDocumentFile = (buffer: any, fileName: string, isPreview = false) => {
  if (buffer) {
    // Create a URL for the buffer
    const url = window.URL.createObjectURL(new Blob([buffer], { type: 'application/pdf' }))

    if (isPreview) {
      // Open the document in a new tab for preview
      window.open(url, '_blank')
    }
    else {
      // Create a link element for downloading
      const link = document.createElement('a')

      link.href = url
      link.setAttribute('download', fileName)

      // Append link to the body, trigger click, and remove link
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }

    // Revoke the URL to free up resources
    window.URL.revokeObjectURL(url)
  }
  else {
    // Display an error message if buffer is not available
    toasterStoreState.displaySnackbar('Something went wrong while downloading the document. Please try again later.', 'error')
  }
}
