import i18n from '@/plugins/i18n'

export const dailyTypes = [
  { text: i18n.global.t('general.today'), value: 'today' },
  { text: i18n.global.t('general.yesterday'), value: 'yesterday' },
  { text: i18n.global.t('general.yesterday_date'), value: 'yesterdayToDate' },
]

export const weeklyTypes = [
  { text: i18n.global.t('general.week'), value: 'week' },
  { text: i18n.global.t('general.last_week'), value: 'lastWeek' },
  { text: i18n.global.t('general.last_date'), value: 'lastWeekToDate' },
]

export const monthTypes = [
  { text: i18n.global.t('general.month'), value: 'month' },
  { text: i18n.global.t('general.last_month'), value: 'lastMonth' },
  { text: i18n.global.t('general.last_month_date'), value: 'lastMonthToDate' },
]
export const yearlyTypes = [
  { text: i18n.global.t('general.year'), value: 'year' },
  { text: i18n.global.t('general.last_year'), value: 'lastYear' },
  { text: i18n.global.t('general.last_year_date'), value: 'lastYearToDate' },
]

export const customTypes = [
  { text: i18n.global.t('general.date'), value: 'date' },
  { text: i18n.global.t('general.range'), value: 'range' },
]

export const saveType = {
  view: 'save_view',
  addNew: 'save_add_new',
  repeat: 'save_repeat',
  saveClose: 'save_close',
  confirmation: 'confirmation',
}

export const statusActiveOrInActive = [
  { text: i18n.global.t('Active'), value: '1' },
  { text: i18n.global.t('Inactive'), value: '0' },
]

export const shareVia = [
  { text: i18n.global.t('SMS'), value: 'sms' },
  { text: i18n.global.t('WhatsApp'), value: 'whatsapp' },
]

export const numberValue = {
  one: 1,
  two: 2,
  three: 3,
  seven: 7,
  zero: 0,
}

export const paginationOptions = reactive({
  page: 1,
  limit: 10,
  sortField: '',
  sortOrder: '',
})

export const notificationOptions = reactive({
  page: 1,
  limit: 10,
  sortField: '',
  sortOrder: '',
  search: false,
})

export const quickConfirmationTableOptions = reactive({
  page: 1,
  limit: 10,
})

export const successMessage = {
  load: 'Load information has been added successfully.',
  offer: 'Offer information has been added successfully.',
  confirmation: 'Confirmation has been created successfully.',
  generatePassword: 'Newly generated password is sent to your registered Mobile and Email ID.',
  confirmationUpdate: 'Confirmation has been updated successfully.',
  freightReceivingAmt: 'Freight Receiving amount has been updated successfully.',
  podReceivingAmt: 'POD Receiving amount has been updated successfully.',
  paymentReceivingAmt: 'Payment Receiving amount has been updated successfully.',
  documentUploaded: 'Document has been uploaded successfully.',
  invoiceDelete: 'Invoice has been deleted successfully.',
  masters: {
    addMessageTemplate: 'Message template has been added successfully.',
    updateMessageTemplate: 'Message template has been updated successfully.',
    createSmsHeader: 'SMS header has been added successfully.',
    updateSmsHeader: 'SMS header has been updated successfully.',
  },
}

export const SMS_TEMPLATE = {
  LOADER: import.meta.env.VITE_SMS_TEMPLATE_LOADER,
  TRUCKER: import.meta.env.VITE_SMS_TEMPLATE_TRUCKER,
  LOAD: import.meta.env.VITE_SMS_TEMPLATE_LOAD,
  OFFER: import.meta.env.VITE_SMS_TEMPLATE_OFFER,
  LOAD_SUGGESTION: import.meta.env.VITE_SMS_TEMPLATE_LOAD_SUGGESTION,
  OFFER_SUGGESTION: import.meta.env.VITE_SMS_TEMPLATE_OFFER_SUGGESTION,
  CONFIRMATION_LINK: import.meta.env.VITE_SMS_TEMPLATE_CONFIRMATION_LINK,
  BANK_DETAILS: import.meta.env.VITE_SMS_TEMPLATE_BANK_DETAILS,
  PAYMENT_LINK: import.meta.env.VITE_SMS_TEMPLATE_PAYMENT_LINK,
}

export const WA_TEMPLATE = {
  LOADER: import.meta.env.VITE_WA_TEMPLATE_LOADER,
  TRUCKER: import.meta.env.VITE_WA_TEMPLATE_TRUCKER,
  LOAD: import.meta.env.VITE_WA_TEMPLATE_LOAD,
  OFFER: import.meta.env.VITE_WA_TEMPLATE_OFFER,
  LOAD_SUGGESTION: import.meta.env.VITE_WA_TEMPLATE_LOAD_SUGGESTION,
  OFFER_SUGGESTION: import.meta.env.VITE_WA_TEMPLATE_OFFER_SUGGESTION,
  CONFIRMATION_LINK: import.meta.env.VITE_WA_TEMPLATE_CONFIRMATION_LINK,
  BANK_DETAILS: import.meta.env.VITE_WA_TEMPLATE_BANK_DETAILS,
  PAYMENT_LINK: import.meta.env.VITE_WA_TEMPLATE_PAYMENT_LINK,
}

export const CNF_SMS_TEMPLATE = {
  LOADER: import.meta.env.VITE_CNF_SMS_TEMPLATE_LOADER,
  TRUCKER: import.meta.env.VITE_CNF_SMS_TEMPLATE_TRUCKER,
  DRIVER: import.meta.env.VITE_CNF_SMS_TEMPLATE_DRIVER,
  LOADING_POINT: import.meta.env.VITE_CNF_SMS_TEMPLATE_LOADING_POINT,
  UNLOADING_POINT: import.meta.env.VITE_CNF_SMS_TEMPLATE_UNLOADING_POINT,
}

export const CNF_WA_TEMPLATE = {
  LOADER: import.meta.env.VITE_CNF_WA_TEMPLATE_LOADER,
  TRUCKER: import.meta.env.VITE_CNF_WA_TEMPLATE_TRUCKER,
  DRIVER: import.meta.env.VITE_CNF_WA_TEMPLATE_DRIVER,
  LOADING_POINT: import.meta.env.VITE_CNF_WA_TEMPLATE_LOADING_POINT,
  UNLOADING_POINT: import.meta.env.VITE_CNF_WA_TEMPLATE_UNLOADING_POINT,
}

export const indianDateFormat = 'DD-MM-YYYY'

export const paymentTypes = {
  netBanking: 'NETBANKING',
}

export const SENTRY_SPAN_ERROR_MSG = '\'set\' on proxy: trap returned falsish for property \'$_sentrySpans\''

export const tableKeys = {
  page: 'page',
  results: 'results',
  search: 'search',
  sortField: 'sortField',
  sortOrder: 'sortOrder',
  limit: 'limit',
}

export const commonKeys = {
  name: 'name',
  businessName: 'business_name',
}

const ADMIN_CONTACT_DETAILS = {
  contactNumber: import.meta.env.VITE_ADMIN_NUMBER || '9566542989',
  email: import.meta.env.VITE_ADMIN_EMAIL || 'admin@navodayatransport.com',
}

export const verificationNote = `For any issues with mobile number or email address verification, please contact our support team at <a href="tel:${ADMIN_CONTACT_DETAILS.contactNumber}">${ADMIN_CONTACT_DETAILS.contactNumber}</a> or email us at <a href="mailto:${ADMIN_CONTACT_DETAILS.email}">${ADMIN_CONTACT_DETAILS.email}</a>.`
