import { setupLayouts } from 'virtual:generated-layouts'
import { createRouter, createWebHistory } from 'vue-router'
import { isUserLoggedIn } from './utils'
import { clearAll as clearLocalStorage, getEncryptedItem } from '@/helpers'
import { canNavigate } from '@layouts/plugins/casl'
import routes from '~pages'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    // ℹ️ We are redirecting to different pages based on role.
    // NOTE: Role is just for UI purposes. ACL is based on abilities.
    {
      path: '/',
      redirect: to => {
        const isLoggedIn = isUserLoggedIn()
        const userData = getEncryptedItem('userData') || {}

        if (userData?.role === 'admin' && isLoggedIn)
          return { name: 'dashboard' }
        if (['loader', 'trucker'].includes(userData?.role) && isLoggedIn)
          return { name: 'user-dashboard' }

        return { name: 'login', query: to.query }
      },
    },
    ...setupLayouts(routes),
  ],

  scrollBehavior: () => {
    // always scroll to top
    window.scrollTo(0, 0)
  },
})

// Docs: https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
router.beforeEach(to => {
  const isLoggedIn = isUserLoggedIn()

  /*

  ℹ️ Commented code is legacy code

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    // ℹ️ Only add `to` query param if `to` route is not index route
    if (!isLoggedIn)
      return next({ name: 'login', query: { to: to.name !== 'index' ? to.fullPath : undefined } })

    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn)
    next('/')

  return next()

    */
  if (canNavigate(to)) {
    if (to.meta.redirectIfLoggedIn && isLoggedIn)
      return '/'
  }
  else if (!to.meta.isPublicPage) {
    if (isLoggedIn) {
      return { name: 'not-authorized' }
    }
    else {
      clearLocalStorage()

      return { name: 'login', query: { to: to.name !== 'index' ? to.fullPath : undefined } }
    }
  }
})

export default router
