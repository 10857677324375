/* eslint-disable indent */
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { bulkUsersStatus, isAdmin, showStatus, usersDefaultValues, usersType } from '@/constants'
import { downloadAndViewFile, getConfirmationStatus } from '@/helpers'
import { useAccountStore } from '@/stores/accounts'
import { useErrorStore } from '@/stores/error'
import { useToasterStore } from '@/stores/toaster'
import {
  contactLengthValidator,
  duplicateMobileOrLandlineValidator,
  numberValidator,
  requiredValidator,
} from '@validators'

dayjs.extend(utc)
dayjs.extend(advancedFormat)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(customParseFormat)

const { state: toasterStoreState } = useToasterStore()
const { state: accountStoreState } = useAccountStore()
const { state: errorStoreState, actions: errorStoreActions } = useErrorStore()

export const currentDate = dayjs()

export const currentISTDate = dayjs().tz('Asia/Kolkata').format()

export const formatDate = (value: string | Date, format = 'DD-MM-YYYY hh:mm A') => {
  return value ? dayjs.tz(value, 'Asia/Kolkata').format(format) : null
}

export const convertUtcToIstFormat = (value: Date, format = 'DD-MM-YYYY hh:mm A') => {
  return value ? dayjs(value).format(format) : null
}

export const formatDateWithoutTime = (value: Date, format = 'DD-MM-YYYY') => {
  return value ? dayjs.tz(value, 'Asia/Kolkata').format(format) : null
}

export const timeFromNow = (date: Date | string, withoutSuffix = false) => {
  return dayjs.tz(date, 'DD-MM-YYYY hh:mm A', 'Asia/Kolkata').fromNow(withoutSuffix)
}

export const startCountdown = (date: any, extraHours: number) => {
  const istDate = dayjs.tz(date, 'Asia/Kolkata')

  const expectedCompletionDate = istDate?.add(extraHours, 'hour')

  const currentTimeInKolkata = dayjs.tz(dayjs(), 'Asia/Kolkata')

  const diff = expectedCompletionDate.diff(currentTimeInKolkata, 'second')

  if (diff <= 0) {
    return null
  }
  else {
    const hours = Math.floor(diff / (3600))
    const minutes = Math.floor((diff % (3600)) / 60)
    const seconds = diff % 60

    const time = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`

    return `(${time})`
  }
}

export const isActiveValue = (value: any) => {
  if (value === true)
    return showStatus.active
  else
    return showStatus.inactive
}

export const checkActiveStatus = (value: any) => {
  if (value === 0)
    return showStatus.active
  else
    return showStatus.inactive
}

export const reset = (value: object, resetData: object) => {
  return Object.assign(value, resetData)
}

export const camelToUnderscore = (key: string) => {
  const result = key.replace(/([A-Z])/g, ' $1')

  return result.split(' ').join('_').toLowerCase()
}

export const removeItems = (array: [], itemsToRemove: []) => {
  return array.filter((v: string) => !itemsToRemove.includes(v))
}

export const textCapitalize = (val: string) => {
  if (!val)
    return val // Return unchanged if the string is empty or null

  const smallFirst = val?.charAt(0).toLowerCase()
  const rest = val.slice(1)

  return smallFirst + rest
}

export const capitalized = (val: string) => {
  if (!val)
    return val // Return unchanged if the string is empty or null
  if (val === usersType.pod)
    return val.toUpperCase()
  const capitalizedFirst = val.charAt(0).toUpperCase()
  const rest = val.slice(1)

  return capitalizedFirst + rest
}

export const firstLetterCapital = (val: string) => {
  if (!val)
    return val // Return unchanged if the string is empty or null

  const smallFirst = val.charAt(0).toUpperCase()
  const rest = val.slice(1).toLowerCase()

  return smallFirst + rest
}

export const isValue = (value: number) => {
  if (value === 1)
    return 'Yes'
  else
    return 'No'
}

export const isConfidential = (value: number) => {
  if (value)
    return 'No'

  return 'Yes'
}

export const hasValue = (value: boolean) => {
  if (value === true)
    return 'Yes'
  else
    return 'No'
}

export const addStatus = (value: number) => {
  if (value === 1)
    return 'Active'
  else
    return 'Inactive'
}

export const checkVisibility = (value: number) => {
  if (value === 1)
    return bulkUsersStatus.public
  else
    return bulkUsersStatus.private
}

// pagination meta for laravel api
export const paginationMeta = computed(() => {
  return <T extends { page: number; results: number }>(options: T, total: number) => {
    const start = (options.page - 1) * options.results + 1
    const end = Math.min(options.page * options.results, total)

    return `Showing ${start} to ${end} of ${total} entries`
  }
})

// pagination meta for node API
export const paginationMetaCal = computed(() => {
  return <T extends { page: number; limit: number }>(options: T, total: number) => {
    const start = (options.page - 1) * options.limit + 1
    const end = Math.min(options.page * options.limit, total)

    return `Showing ${start} to ${end} of ${total} entries`
  }
})

export const processBarColor = (val: number) => {
  if (val === 0)
    return ''
  if (val <= 20)
    return 'process-light'
  if (val <= 40)
    return 'process-dark'
  if (val <= 55)
    return 'process-red'
  if (val <= 70)
    return 'process-blue'
  if (val <= 85)
    return 'process-yellow'
  if (val <= 100)
    return 'process-green'
}

export const getTruckCapacity = (value: number) => {
  switch (value) {
    case 4:
      return '1-5 tons'
    case 5:
      return '6-16 tons'
    case 0:
      return '17-21 tons'
    case 1:
      return '22-25 tons'
    case 2:
      return '26-30 tons'
    case 3:
      return '31-35 tons'
    case 6:
      return '36-50 tons'

    default:
      return ''
  }
}

export const getCapacityByRange = (value: number) => {
  if (value >= 1 && value < 6)
    return 4
  if (value >= 6 && value < 17)
    return 5
  if (value >= 17 && value < 22)
    return 0
  if (value >= 22 && value < 26)
    return 1
  if (value >= 26 && value < 31)
    return 2
  if (value >= 31 && value < 36)
    return 3
  if (value >= 36 && value <= 50)
    return 6
}

export const getDocumentsNames = (data: any, selected: any) => (data.filter(item => selected.includes(item.id)).map(item => item.name))

export const getDocumentDetails = (data: any, selected: any) => (data.filter(item => selected.includes(item.id)))

export const getMobileValue = (val: object[]) => val?.map(i => i.value || '')

export const getMaterialName = (item: any, isFilter = false) => item?.filter((val: any) => !isFilter || val?.is_active)?.map((val: string) => val?.id)

export const getConditionIds = (item: any, isFilter = false) => item?.filter((val: any) => !isFilter || val?.is_active).map((val: any) => val?.condition_id)

export const getRoutesId = (val1: [], val2: []) => {
  const hasRoutesId: any = []

  val1.forEach((item: any) => {
    const isItemInVal2 = val2.find((item2: any) => item2.display_name === item.display_name)
    if (isItemInVal2)
      hasRoutesId.push(item.id)
  })

  return hasRoutesId
}

export const getOfferName = (item: any, isFilter = false) => item.filter((val: any) => !isFilter || val?.master_routes?.is_active)?.map((val: string) => val?.place_id)

export const removeEditFromPath = (path: any) => {
  return path.split('/').filter((segment: any) => segment !== 'edit').join('/')
}

export const isCapacityInRange = (capacity: any, range: any) => {
  const getRanges = getTruckCapacity(Number(range))

  const [min, max] = getRanges.split('-')

  return capacity >= min && capacity <= max
}

// use whenever thing is in array index eg: landline no
export const mobileOrLandlineValidationRules = computed(() => (val: any, index: number, numberType = 'mobile') => {
  return [
    numberType === 'mobile' && !index ? requiredValidator : true,
    numberValidator,
    contactLengthValidator(val[index], numberType === usersDefaultValues.landline ? 11 : 10),

    // [Note]: Need to check validation
    index === 0 ? duplicateMobileOrLandlineValidator(val[0], val[1], numberType) : true,
    index === 0 ? duplicateMobileOrLandlineValidator(val[0], val[2], numberType) : true,
    index === 1 ? duplicateMobileOrLandlineValidator(val[1], val[0], numberType) : true,
    index === 1 ? duplicateMobileOrLandlineValidator(val[1], val[2], numberType) : true,
    index === 2 ? duplicateMobileOrLandlineValidator(val[2], val[0], numberType) : true,
    index === 2 ? duplicateMobileOrLandlineValidator(val[2], val[1], numberType) : true,
  ]
})

// use whenever mobile is in array & 1st contact is not required
export const mobileNoRequiredValidationRules = computed(() => (val: any, index: number, numberType = 'mobile') => {
  return [
    numberValidator,
    contactLengthValidator(val[index], 10),

    // [Note]: Need to check validation
    index === 0 ? duplicateMobileOrLandlineValidator(val[0], val[1], numberType) : true,
    index === 0 ? duplicateMobileOrLandlineValidator(val[0], val[2], numberType) : true,
    index === 1 ? duplicateMobileOrLandlineValidator(val[1], val[0], numberType) : true,
    index === 1 ? duplicateMobileOrLandlineValidator(val[1], val[2], numberType) : true,
    index === 2 ? duplicateMobileOrLandlineValidator(val[2], val[0], numberType) : true,
    index === 2 ? duplicateMobileOrLandlineValidator(val[2], val[1], numberType) : true,
  ]
})

// use when preferred contact and array in mobile are used
export const mobileWithPreferredContactValidationRules = computed(() => (val: any, index: number, val2: string, numberType = 'mobile') => {
  return [
    numberValidator,
    contactLengthValidator(val[index], numberType === usersDefaultValues.landline ? 11 : 10),
    contactLengthValidator(val2, numberType === usersDefaultValues.landline ? 11 : 10),

    // [Note]: Need to check validation
    index === 0 ? duplicateMobileOrLandlineValidator(val[0], val[1], numberType) : true,
    index === 0 ? duplicateMobileOrLandlineValidator(val[0], val2, numberType) : true,
    index === 1 ? duplicateMobileOrLandlineValidator(val[1], val[0], numberType) : true,
    index === 1 ? duplicateMobileOrLandlineValidator(val[1], val2, numberType) : true,
    index === 2 ? duplicateMobileOrLandlineValidator(val2, val[0], numberType) : true,
    index === 2 ? duplicateMobileOrLandlineValidator(val2, val[1], numberType) : true,
  ]
})

export const isResetMasterSearchField = ref(false)

export const selectStatusById = computed(() => (data: [], selectedIds: []) => {
  return data.filter(val => selectedIds.includes(val.id)).map(val2 => val2.status)
})

export const selectConfirmationStatus = computed(() => (data: [], selectedIds: []) => {
  return data.filter(val => selectedIds.includes(val.id)).map(val2 => getConfirmationStatus(val2.status, val2.isCompleted)?.text)
})

export const imagesAfterDelete = (images: [], deleteImage: []) => {
  let currentIndex = ''
  images.forEach((val, index) => {
    if (deleteImage.name === val.name)
      currentIndex = index
  })

  images.splice(currentIndex, 1)

  return images.filter(val => {
    return val.id === undefined
  })
}

export const resetReactiveData = (value: object, resetData: object) => {
  value = { ...value, ...resetData }
}

export const restrictInput = (e: any) => {
  const inputKeyCode = e.keyCode ? e.keyCode : e.which

  if (inputKeyCode != null && inputKeyCode === 45 || inputKeyCode === 101)
    e.preventDefault()

  if (e.target.value != null && e.target.value.includes('.') && (e.target.value.split('.')[1].length > 1))
    e.preventDefault()
}

export const maxPastLoadingDate = () => {
  if (isAdmin.value) {
    const target = dayjs().subtract(5, 'day').format('DD-MM-YYYY')

    return {
      minDate: target,
    }
  }

  return {
    minDate: 'today',
  }
}

export const maxFutureLoadingDate = () => {
  const target = dayjs().add(25, 'day').format('DD-MM-YYYY')

  return {
    maxDate: target,
  }
}

export const restrictFutureDate = {
  maxDate: 'today', // Restrict the future Date
}

export const restrictPastDate = {
  minDate: 'today', // Restrict the future Date
}

export const maxPastReportDate = () => {
  return {
    minDate: dayjs().subtract(1, 'year').format('YYYY-MM-DD'), // Restrict the future Date
  }
}

export const restrictNegative = (e: any) => {
  const inputKeyCode = e.keyCode ? e.keyCode : e.which

  // restrict negative, letter e and decimal point values
  if (inputKeyCode != null && inputKeyCode === 45 || inputKeyCode === 101 || inputKeyCode === 46)
    e.preventDefault()
}

export const toNumberFixed = (value: any, digits = 2) => value.toFixed(digits)

export const calculateFileSizeInMB = (file: File): number => {
  return file / (1024 * 1024) // Convert bytes to MB
}

const router = useRouter()

export const onBack = () => {
  router.back()
}

export const debounce = (func, wait) => {
  let timeout

  return (...args) => {
    clearTimeout(timeout)
    // eslint-disable-next-line @typescript-eslint/no-invalid-this
    timeout = setTimeout(() => func.apply(this, args), wait)
  }
}

export const getUserPrimaryContactDetails = (primaryContact: any) => {
  return {
    fullname: primaryContact?.fullname,
    designation: primaryContact?.designation,
    email: primaryContact?.email,
    preferred_contact: primaryContact?.preferred_contact ?? primaryContact?.phone,
    mobiles: getMobileValue(primaryContact?.mobiles),
    landlines: getMobileValue(primaryContact?.landlines),
  }
}

export const getPreferredMobileValue = (val: object[]) => val.slice(1)?.map(i => i.value || '')

export const dateUTCToLocal = (value: any, format = 'YYYY-MM-DD') => {
  return dayjs.utc(value).local().format(format)
}

export const dateUTCFormat = (value: any) => {
  return dayjs(value).format('YYYY-MM-DD')
}

export const acceptNumbers = (e: any) => {
  const inputKeyCode = e.keyCode ? e.keyCode : e.which
  const value = e.target.value

  // Allow control keys (backspace, delete, arrow keys, etc.)
  const controlKeys = [8, 37, 39]
  if (controlKeys.includes(inputKeyCode))
    return

  // Only allow digits and a single dot
  if (
    (inputKeyCode < 48 || inputKeyCode > 57)
    && inputKeyCode !== 46
  )
    return e.preventDefault()

  // Only allow one dot
  if (inputKeyCode === 46 && (value.includes('.') || !value || value.length > 8))
    return e.preventDefault()

  // Restrict to two digits after the dot
  if (value.includes('.')) {
    const parts = value.split('.')
    if (parts[1].length >= 2 && e.target.selectionStart > value.indexOf('.'))
      return e.preventDefault()
  }

  // Restrict maximum length to 9 characters
  if (value.length > 9 && !controlKeys.includes(inputKeyCode))
    e.preventDefault()
}

export const acceptLwh = (e: any) => {
  const inputKeyCode = e.keyCode ? e.keyCode : e.which
  const value = e.target.value

  // Allow control keys (backspace, delete, arrow keys, etc.)
  const controlKeys = [8, 37, 39]
  if (controlKeys.includes(inputKeyCode))
    return

  // Only allow digits and a single dot
  if (
    (inputKeyCode < 48 || inputKeyCode > 57) && inputKeyCode !== 46
  )
    return e.preventDefault()

  // Only allow one dot
  if (inputKeyCode === 46 && (value.includes('.') || !value || value.length > 3))
    return e.preventDefault()

  // Restrict to two digits after the dot
  if (value.includes('.')) {
    const parts = value.split('.')
    if ((parts[1].length >= 2 && e.target.selectionStart > value.indexOf('.')) || parts[0].length >= 3)
      return e.preventDefault()
  }
  if (!value.includes('.') && inputKeyCode !== 46 && value.length > 2)
    return e.preventDefault()
}

export const extractCityState = (parts: any) => {
  return parts ? parts?.split(', ').slice(0, 2).join(', ') : ''
}

export const onWebsiteClick = (val: string) => {
  if (val) {
    // Check if the URL starts with "http://" or "https://"
    const urlPattern = /^https?:\/\//i
    if (!urlPattern.test(val)) {
      // If not, prepend "http://"
      val = `http://${val}`
    }
    window.open(val, '_blank')
  }
}

export function debounceSearch<T extends (...args: any[]) => void>(func: T): (...args: Parameters<T>) => void {
  let timeout: ReturnType<typeof setTimeout> | null = null

  return function (...args: Parameters<T>): void {
    if (timeout)
      clearTimeout(timeout)

    timeout = setTimeout(() => {
      func(...args)
    }, 500)
  }
}

export const maskMobileNumber = (contactNumber: string) => `XXXXXX${(contactNumber).slice(-4)}`

export const maskEmail = (email: string) => {
  const [localPart, domain] = email.split('@')
  if (localPart.length <= 2) {
    // If the local part is 2 characters or less, we won't mask it
    return email
  }

  const firstTwoChars = localPart.substring(0, 2)
  const maskedPart = 'X'.repeat(localPart.length - 2)

  return `${firstTwoChars}${maskedPart}@${domain}`
}

export const isOnlyDigits = (value: string) => {
  const regex = /^[0-9]+$/

  return regex.test(value)
}

export const isValidMobileNumber = (mobileNumber: string) => {
  const regex = /^[0-9]{10}$/

  return regex.test(mobileNumber)
}

export const isValidEmail = (email: string) => {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i

  return regex.test(email)
}
export const exportFile = async (
  fileType: 'excel' | 'pdf',
  moduleType: string,
  accountsSearchFields: Record<string, any>,
  accountStoreActions: any,
  fileName: string,

) => {
  accountStoreState.hasLoader = true

  const filteredFields = Object.keys(accountsSearchFields)
    .filter(key => accountsSearchFields[key] !== '')
    .reduce((obj, key) => {
      obj[key] = accountsSearchFields[key]

      return obj
    }, {} as Record<string, any>)

  try {
    const buffer: any = await accountStoreActions.exportFiles(moduleType, fileType, filteredFields)

    downloadAndViewFile(buffer, fileType, `${fileName}`)
  }
  catch (error) {
    console.error('Error exporting file:', error)
    toasterStoreState.displaySnackbar('Failed to export the file. Please try again later.', 'error')
  }
  finally {
    accountStoreState.hasLoader = false
  }
}

export const clearError = (errorKey: string) => {
  if ((errorStoreState as any)?.[errorKey])
    errorStoreActions.clearFieldError(errorKey)
}

export const getPaymentMethod = (method: string) => {
  if (method === 'NETBANKING')
    return 'A/C Transfer'

  if (method === 'MC_BOOK')
    return ''

  return firstLetterCapital(method)
}

export const displayAmountType = (amountType: string) => {
  if (amountType === 'CROSSING_COMMISSION')
    return amountType = 'Crossing + Commission'
  else if (amountType === 'JOURNAL')
    return amountType = ''
  else
    return ['FREIGHT', 'COMMISSION'].includes(amountType) ? firstLetterCapital(amountType) : amountType
}

export const getIndustries = (industries: any) => {
  return industries?.length
    ? [{
        id: industries?.at(0)?.industry_id,
        name: industries?.at(0)?.name,
      }]
    : []
}

export const getDistinctValue = (items: any, key: string) => {
  const uniqueKeys = Array.from(new Set(items.map((item: any) => item?.[key])))

  return uniqueKeys.map((uniqueKey: any) => items.find((item: any) => item?.[key] === uniqueKey))
}

export const currentISTDateTZFormat = () => new Date()

export const scrollToTop = (to = 0) => {
  window.scrollTo({
    top: to,
    behavior: 'smooth',
  })
}
