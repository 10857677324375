import { isEmpty, isEmptyArray, isNullOrUndefined } from './index'
import i18n from '@/plugins/i18n/index'

// 👉 Required Validator
export const requiredValidator = (value: unknown, fieldName?: string) => {
  if (isNullOrUndefined(value) || isEmptyArray(value) || value === false)
    return fieldName ? 'Please complete the required field(s)' : 'This field is required'

  return !!String(value).trim().length || (fieldName ? 'Please complete the required field(s)' : 'This field is required')
}

export const documentTypeValidator = (value: unknown) => {
  if (isEmpty(value))
    return true

  const regex = /^[a-zA-Z0-9 _-]+$/

  return regex.test(String(value)) || 'Document type name can only have letters, numbers, - & _'
}

// 👉 Email Validator
export const emailValidator = (value: unknown) => {
  if (isEmpty(value))
    return true

  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i

  if (Array.isArray(value))
    return value.every(val => re.test(String(val))) || i18n.global.t('validation.email')

  return re.test(String(value)) || i18n.global.t('validation.email')
}

// 👉 Password Validator
export const passwordValidator = (password: string) => {
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/

  const validPassword = regExp.test(password)

  return (
    // eslint-disable-next-line operator-linebreak
    validPassword ||
    i18n.global.t('validation.password')
  )
}

// 👉 Confirm Password Validator
export const confirmedValidator = (value: string, target: string) =>

  value === target || i18n.global.t('validation.confirm_password')

// 👉 Same Password Validator
export const samePasswordValidator = (value: string, target: string) =>

  value !== target || i18n.global.t('validation.current_password')

// 👉 Between Validator
export const betweenValidator = (value: unknown, min: number, max: number) => {
  const valueAsNumber = Number(value)

  return (Number(min) <= valueAsNumber && Number(max) >= valueAsNumber) || `Enter number between ${min} and ${max}`
}

// 👉 Length Between Validator
export const betweenLengthValidator = (value: unknown, mixLength: number, maxLength: number) => {
  if (isEmpty(value))
    return true
  const valueLength = String(value).length

  return valueLength >= mixLength && valueLength <= maxLength || `Enter number between ${mixLength} and ${maxLength}`
}

// 👉 Max Length Validator
export const maxLengthValidator = (value: unknown, mixLength: number, maxLength: number) => {
  if (isEmpty(value))
    return true
  const valueLength = String(value).length

  return valueLength >= mixLength && valueLength <= maxLength || `This field must contain max ${maxLength} characters`
}

// 👉 Integer Validator
export const integerValidator = (value: unknown) => {
  if (isEmpty(value))
    return true

  if (Array.isArray(value))
    return value.every(val => /^-?[0-9]+$/.test(String(val))) || 'This field must be an integer'

  return /^-?[0-9]+$/.test(String(value)) || 'This field must be an integer'
}

// 👉 Integer Validator
export const numberValidator = (value: unknown) => {
  if (isEmpty(value))
    return true

  return /^-?[0-9]+$/.test(String(value)) || i18n.global.t('validation.number')
}

// 👉 Regex Validator
export const regexValidator = (value: unknown, regex: RegExp | string): string | boolean => {
  if (isEmpty(value))
    return true

  let regeX = regex
  if (typeof regeX === 'string')
    regeX = new RegExp(regeX)

  if (Array.isArray(value))
    return value.every(val => regexValidator(val, regeX))

  return regeX.test(String(value)) || 'The Regex field format is invalid'
}

// 👉 Alpha Validator
export const alphaValidator = (value: unknown) => {
  if (isEmpty(value))
    return true

  return /^[A-Z]*$/i.test(String(value)) || 'The Alpha field may only contain alphabetic characters'
}

// 👉 URL Validator
export const urlValidator = (value: unknown) => {
  if (isEmpty(value))
    return true

  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/

  return re.test(String(value)) || 'URL is invalid'
}

// 👉 Length Validator
export const lengthValidator = (value: unknown, length: number) => {
  if (isEmpty(value))
    return true

  return String(value).length === length || `The Min Character field must be at least ${length} characters`
}

// 👉 Alpha-dash Validator
export const alphaDashValidator = (value: unknown) => {
  if (isEmpty(value))
    return true

  const valueAsString = String(value)

  return /^[0-9A-Z_-]*$/i.test(valueAsString) || 'All Character are not valid'
}

// 👉 Length Validator
export const contactLengthValidator = (value: unknown, length: number) => {
  if (isEmpty(value))
    return true

  return String(value).length === length || (length === 10 ? 'Mobile no. must be of 10 digits.' : `Landline no must be of ${length} digits.`)
}

// 👉 Truck No. Validator
// export const TruckNoLengthValidator = (value: unknown) => {
//   if (isEmpty(value))
//     return true

//   return String(value).length >= 15 || i18n.global.t('validation.truck_no')
// }

export const alphanumericValidator = (value: unknown) => {
  if (isEmpty(value))
    return true

  return /^(?=.*[A-Z])(?=.*\d)[A-Z0-9]+$/i.test(String(value))
         || 'The truck number field must include only letters and numbers'
}

export const truckNoLengthValidator = (value: unknown) => {
  if (isEmpty(value))
    return true

  return /^.{8,12}$/.test(String(value))
         || 'The field must be between 8 and 12 characters long'
}

// 👉 Duplicate Mobile or Landline Validator
export const duplicateMobileOrLandlineValidator = (value: string, target: string, numberType = 'mobile') => {
  if (isEmpty(value))
    return true

  return value !== target || `Duplicate ${numberType} number is not allowed`
}

// 👉 Length Validator
export const fieldLengthValidator = (value: unknown, title: string, length = 50) => {
  if (isEmpty(value))
    return true

  return String(value).length <= length || `${title} must not be greater than ${length} character length`
}

// 👉 Website Validator
export const websiteValidator = (value: unknown) => {
  if (isEmpty(value))
    return true

  const re = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i

  if (Array.isArray(value))
    return value.every(val => re.test(String(val))) || i18n.global.t('validation.website')

  return re.test(String(value)) || i18n.global.t('validation.website')
}

export const greaterThenZeroValidator = (value: unknown) => {
  if (isEmpty(value))
    return true

  return parseFloat(value) > 0 || i18n.global.t('validation.greater_zero')
}

export const paymentValueValidator = (value: unknown) => {
  if (isEmpty(value))
    return true

  return parseInt(value) >= 100 || i18n.global.t('Please enter amount greater than 100')
}

export const gstNoValidator = (value: unknown) => {
  if (isEmpty(value))
    return true

  const re = /^([0-9]{2})([A-Z]{5}[0-9]{4}[A-Z]{1})([A-Z0-9]{1})(Z)([A-Z0-9]{1})$/

  return re.test(String(value)) || 'The GST No. field format is invalid.'
}

export const panNoValidator = (value: unknown) => {
  if (isEmpty(value))
    return true

  const re = /^([A-Z]{5})([0-9]{4})([A-Z]{1})$/

  return re.test(String(value)) || 'The PAN No. field format is invalid.'
}

// 👉 Aadhar No length Validator
export const aadharValidator = (value: unknown) => {
  if (isEmpty(value))
    return true

  return String(value).length === 12 || 'Aadhar no. must be of 12 digits.'
}
