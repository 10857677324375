// 👉 headers

import i18n from '@/plugins/i18n'

export const clustersHeaders = [
  { title: 'ID', key: 'id' },
  { title: 'Cluster Name', key: 'place_name', width: 750 },
  { title: 'Created Timestamp', key: 'created_at', align: 'start' },
  { title: 'Updated Timestamp', key: 'updated_at', align: 'start' },
  { title: 'Actions', key: 'actions', sortable: false, align: 'center' },
]
export const routesHeaders = [
  { title: 'ID', key: 'id' },
  { title: 'Route Name', key: 'place_name', width: 500 },
  { title: 'Display Name', key: 'display_name' },
  { title: 'Created Timestamp', key: 'created_at', align: 'start' },
  { title: 'Updated Timestamp', key: 'updated_at', align: 'start' },
  { title: 'Actions', key: 'actions', sortable: false, align: 'center' },
]

export const materialsHeaders = [
  { title: 'ID', key: 'id' },
  { title: 'Material/Products Name', key: 'name', width: 750 },
  { title: 'Created Timestamp', key: 'created_at', align: 'start' },
  { title: 'Updated Timestamp', key: 'updated_at', align: 'start' },
  { title: 'Actions', key: 'actions', sortable: false, align: 'center' },
]

export const smsHeaders = [
  { title: 'ID', key: 'id' },
  { title: 'SMS Header Name', key: 'name', width: 750 },
  { title: 'Actions', key: 'actions', sortable: false, align: 'center' },
]

export const industriesHeaders = [
  { title: 'ID', key: 'id' },
  { title: 'Industry Name', key: 'name', width: 750 },
  { title: 'Created Timestamp', key: 'created_at', align: 'start' },
  { title: 'Updated Timestamp', key: 'updated_at', align: 'start' },
  { title: 'Actions', key: 'actions', sortable: false, align: 'center' },
]

export const conditionsHeaders = [
  { title: 'ID', key: 'id' },
  { title: 'Condition Name', key: 'name', width: 550 },
  { title: 'Section', key: 'type' },
  { title: 'Created Timestamp', key: 'created_at', align: 'start' },
  { title: 'Updated Timestamp', key: 'updated_at', align: 'start' },
  { title: 'Actions', key: 'actions', sortable: false, align: 'center' },
]

export const remarkHeaders = [
  { title: 'ID', key: 'id' },
  { title: 'Code', key: 'code', width: 200 },
  { title: 'Description', key: 'description', width: 550 },
  { title: 'Created Timestamp', key: 'created_at', align: 'start' },
  { title: 'Updated Timestamp', key: 'updated_at', align: 'start' },
  { title: 'Actions', key: 'actions', sortable: false, align: 'center' },
]

export const documentTypeHeaders = [
  { title: 'ID', key: 'id' },
  { title: 'Document Name', key: 'name', width: 300 },
  { title: 'Section', key: 'type', align: 'start' },
  { title: 'Is Primary', key: 'is_primary', sortable: false, align: 'start' },
  { title: 'Is Confidential', key: 'is_public', sortable: false, align: 'start' },
  { title: 'Has Expiry', key: 'has_expiry', sortable: false, align: 'start' },
  { title: 'Created Timestamp', key: 'created_at', align: 'start' },
  { title: 'Updated Timestamp', key: 'updated_at', align: 'start' },
  { title: 'Actions', key: 'actions', sortable: false, align: 'center' },
]

export const messageTemplate = [
  { title: 'ID', key: 'id' },
  { title: 'DLT Template ID', key: 'templateId' },
  { title: 'Template Name', key: 'name' },
  { title: 'Template Type', key: 'templateType' },
  { title: 'Category', key: 'category' },
  { title: 'Sub Category', key: 'subCategory' },
  { title: 'Created Timestamp', key: 'createdAt', align: 'start' },
  { title: 'Updated Timestamp', key: 'updatedAt', align: 'start' },
  { title: 'Status', key: 'isActive' },
  { title: 'Actions', key: 'actions', sortable: false, align: 'center' },
]

export const documentHeaders = [
  { title: 'Doc ID', key: 'id', sortable: false },
  { title: 'Document Name', key: 'name', width: 400, sortable: false },
  { title: '', key: '', align: '', sortable: false },
  { title: 'CNF ID', key: 'owner.confirmation.formatted_id', align: 'start', is_bold: true },
  { title: 'Document Section', key: 'type', align: 'start', sortable: false },
  { title: '', key: '', align: '', sortable: false },
  { title: 'Business ID', key: 'business_id', align: 'start', sortable: false, is_bold: true },
  { title: 'Has Expiry', key: 'has_expiry', sortable: false, align: 'start' },
  { title: 'Is Confidential', key: 'is_public', sortable: false, align: 'start' },
  { title: 'Business Name', key: 'business_name', align: 'start', sortable: false, is_bold: true },
  { title: 'Valid From', key: 'valid_from', align: 'start', sortable: false },
  { title: 'Is Primary', key: 'is_primary', sortable: false, align: 'start' },
  { title: 'Created Timestamp', key: 'created_at', align: 'start' },
  { title: 'Valid To', key: 'valid_to', align: 'start', sortable: false },
  { title: 'Status', key: 'status', align: 'start', sortable: false, is_bold: true },
  { title: 'Updated Timestamp', key: 'updated_at', align: 'start' },
  { title: '', key: '', align: '', sortable: false },
  { title: 'Remarks', key: 'reason', align: 'start', sortable: false },
]

export const rejectDocumentHeaders = [
  { title: 'Document Name', key: 'name' },
  { title: 'Document Section', key: 'type' },
  { title: 'Business Name', key: 'business_name' },
  { title: 'Confirmation ID', key: 'owner.confirmation.formatted_id' },
  { title: 'Is Primary', key: 'is_primary' },
  { title: 'Is Confidential', key: 'is_public' },
  { title: 'Has Expiry', key: 'has_expiry' },
  { title: 'Valid From', key: 'valid_from' },
  { title: 'Valid To', key: 'valid_to' },
]

export const documentTypesHeaders = [
  { title: 'ID', key: 'id' },
  { title: 'Document Name', key: 'name' },
  { title: 'Is Primary', key: 'is_primary', sortable: false, align: 'start' },
  { title: 'Is Confidential', key: 'is_public', sortable: false, align: 'start' },
  { title: 'Valid From', key: 'valid_from', align: 'start' },
  { title: 'Valid To', key: 'valid_to', align: 'center' },
  { title: 'Status', key: 'status', align: 'start' },
  { title: 'Actions', key: 'actions', sortable: false, align: 'center' },
]

export const notificationsHeaders = [
  { title: i18n.global.t('ID'), key: 'id', sortable: false },
  { title: i18n.global.t('Notification Title'), key: 'title', align: 'start', sortable: false },
  { title: i18n.global.t('Notification Message'), key: 'message', width: 600, align: 'start', sortable: false },
  { title: i18n.global.t('Date & Time'), key: 'created_at', align: 'start' },
  { title: i18n.global.t('Status'), key: 'is_read' },
  { title: i18n.global.t('Actions'), key: 'actions', sortable: false, align: 'center' },
]

export const operatorsHeaders = [
  { title: i18n.global.t('ID'), key: 'id', sortable: false },
  { title: i18n.global.t('Full Name'), key: 'name', align: 'start', sortable: false },
  { title: i18n.global.t('Mobile No.'), key: 'mobiles', sortable: false },
  { title: i18n.global.t('Email Address'), key: 'email', align: 'start', sortable: false },
  { title: i18n.global.t('Role'), key: 'role', align: 'start', sortable: false },
  { title: i18n.global.t('Actions'), key: 'actions', sortable: false, align: 'center' },
]

export const usersNotificationsHeaders = [
  { title: 'Notification Title', key: 'notification_title', width: 1100, align: 'start', sortable: false },
  { title: 'Date & Time', key: 'date_time', align: 'start', sortable: false },
  { title: 'Actions', key: 'actions', sortable: false, align: 'center' },
]

export const mastersMcBookHeaders = [
  { title: 'ID', key: 'id', sortable: false },
  { title: 'Full Name', key: 'fullname', sortable: false },
  {
    title: 'Mobile No.',
    key: 'mobile',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Landline No.',
    key: 'landline',
    align: 'start',
    sortable: false,
  },
  { title: 'Email Address', key: 'email', align: 'start', sortable: false },
  { title: 'Designation', key: 'designation', align: 'start', sortable: false },
  { title: 'Work With', key: 'work_with', align: 'start', sortable: false },
  { title: 'Primary Name', key: 'owner.user.fullname', align: 'start', sortable: false },
  { title: 'Actions', key: 'actions', sortable: false, align: 'center' },
]

export const driversHeaders = [
  { title: 'ID', key: 'id', sortable: false },
  { title: 'Name', key: 'fullname', sortable: false },
  {
    title: 'Mobile',
    key: 'mobiles',
    align: 'start',
    sortable: false,

    render(item: any) {
      return item.columns.mobiles?.[0]?.value
    },
  },
  {
    title: 'Landline',
    key: 'landlines',
    align: 'start',
    sortable: false,

    render(item: any) {
      return item.columns.landlines?.[0]?.value
    },
  },
  { title: 'Email Address', key: 'email', align: 'start', sortable: false },
  { title: 'Designation', key: 'designation', align: 'start', sortable: false },
  { title: 'Actions', key: 'actions', sortable: false, align: 'center' },
]

export const trucksHeaders = [
  { title: 'ID', key: 'id' },
  { title: 'Truck No.', key: 'truck_number' },
  { title: 'Tyres', key: 'tyres', align: 'start' },
  { title: 'LWH (ft)', key: 'truck_length', align: 'start', sortable: false },
  { title: 'Capacity', key: 'capacity', align: 'start' },
  { title: 'Chassis No.', key: 'chassis_number', align: 'start', sortable: false },
  { title: 'Engine No.', key: 'engine_number', align: 'start', sortable: false },
  { title: 'Doc. Verification', key: 'is_docs_verified', align: 'start' },
  { title: 'Status', key: 'is_active', align: 'start' },
  { title: 'Created Timestamp', key: 'created_at', align: 'start' },
  { title: 'Updated Timestamp', key: 'updated_at', align: 'center' },
  { title: 'Actions', key: 'actions', sortable: false, align: 'center' },
]

export const banksHeaders = [
  { title: 'ID', key: 'id' },
  { title: 'Account Name', key: 'accountName', sortable: false },
  { title: 'Bank Name', key: 'bankName', sortable: false },
  { title: 'Account No.', key: 'bankNumber', align: 'start', sortable: false },
  { title: 'IFSC Code', key: 'bankIfscCode', align: 'start', sortable: false },
  { title: 'Branch Address', key: 'bankBranchAddress', align: 'start', sortable: false },
  { title: 'Account Type', key: 'bankType', align: 'start', sortable: false },
  { title: 'PAN No.', key: 'bankPanNumber', align: 'start', sortable: false },
  { title: 'Primary', key: 'isDefault', align: 'center', sortable: false },
  { title: 'Created Timestamp', key: 'createdAt', align: 'start' },
  { title: 'Updated Timestamp', key: 'updatedAt', align: 'start' },
  { title: 'Actions', key: 'actions', sortable: false, align: 'center' },
]

export const mcBookHeaders = [
  { title: 'ID', key: 'id' },
  { title: 'Full Name', key: 'fullNames', sortable: false },
  {
    title: 'Mobile No.',
    key: 'mobile',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Landline No.',
    key: 'landline',
    align: 'start',
    sortable: false,
  },
  { title: 'Designation', key: 'designation', align: 'start', sortable: false },
  { title: 'Associated With', key: 'workableType', align: 'start', sortable: false },
  { title: 'Business Name', key: 'businessNames', align: 'start', sortable: false },
  { title: 'Balance', key: 'balance', align: 'start', sortable: false },
  { title: 'Actions', key: 'actions', sortable: false, align: 'center' },
]

export const branchesHeaders = [
  { title: 'ID', key: 'id', sortable: false },
  { title: 'Business Name', key: 'business_name', align: 'start' },
  { title: 'Trading Name', key: 'trading_name', align: 'start', sortable: false },
  { title: 'Area', key: 'bank_ifsc_code', align: 'start', sortable: false },
  { title: 'Charges', key: 'bank_branch_address', align: 'start', sortable: false },
  {
    title: 'Mobile',
    key: 'mobiles',
    align: 'start',
    sortable: false,

    render(item: any) {
      return item.columns.mobiles?.[0]?.value
    },
  },
  {
    title: 'Landline',
    key: 'landlines',
    align: 'start',
    sortable: false,

    render(item: any) {
      return item.columns.landlines?.[0]?.value
    },
  },
  { title: 'Email Address', key: 'email', align: 'start', sortable: false },
  { title: 'Designation', key: 'designation', align: 'start', sortable: false },
  { title: 'Actions', key: 'actions', sortable: false, align: 'center' },
]

export const ledgerHeaders = [
  { title: 'ID', key: 'id', sortable: false },
  { title: 'Date', key: 'date', align: 'start', width: 150 },
  { title: 'CNF ID', key: 'confirmationId', align: 'start', sortable: false },
  { title: 'Description', key: 'description', align: 'start', sortable: false, width: 200 },
  { title: 'Amount Type', key: 'amountType', align: 'start', sortable: false, width: 100 },
  { title: 'Tran. Type', key: 'paymentMethod', align: 'start', sortable: false },
  { title: 'Reference', key: 'reference', align: 'start', sortable: false },
  { title: 'Receivable', key: 'receivable', align: 'start', sortable: false },
  { title: 'Received', key: 'received', align: 'start', sortable: false },
  { title: 'Payable', key: 'payable', align: 'start', sortable: false },
  { title: 'Paid', key: 'paid', align: 'start', sortable: false },
  { title: 'Actions', key: 'actions', sortable: false, align: 'center' },
]

export const bannerHeaders = [
  { title: 'Thumbnail', key: 'url', width: 250, sortable: false },
  { title: 'Banner Title', key: 'name', sortable: false },
  { title: 'User Type', key: 'type', sortable: false },
  { title: 'Created Timestamp', key: 'created_at', align: 'start' },
  { title: 'Updated Timestamp', key: 'updated_at', align: 'start' },
  { title: 'Actions', key: 'actions', align: 'center', width: 150, sortable: false },
]

export const publicBasicInfoColumns = [
  { name: i18n.global.t('Loading Points'), value: 'loadingPoints' },
  { name: i18n.global.t('Unloading Points'), value: 'unloadingPoints' },
  { name: i18n.global.t('Truck No.'), value: 'truckNumber' },
  { name: i18n.global.t('Tyres'), value: 'tyres' },
  { name: i18n.global.t('Date'), value: 'loadingDate' },
  { name: i18n.global.t('Capacity / No. of Units'), value: 'capacity' },
  { name: i18n.global.t('Per Ton / Per Unit Price'), value: 'freightAmount' },
  { name: i18n.global.t('Freight Method'), value: 'freightMethod' },
  { name: i18n.global.t('Total Freight'), value: 'totalAmount' },
  { name: i18n.global.t('Adv.'), value: 'advanceAmount', condition: false },
  { name: i18n.global.t('To Pay'), value: 'toPayAmount', condition: false },
  { name: i18n.global.t('POD'), value: 'podAmount', condition: false },
]

export const adminBasicInfoColumns = [
  { name: i18n.global.t('Date'), value: 'loadingDate' },
  { name: i18n.global.t('Loading Points'), value: 'loadingPoints' },
  { name: i18n.global.t('Unloading Points'), value: 'unloadingPoints' },
  { name: i18n.global.t('Capacity / No. of Units'), value: 'capacity' },
  { name: i18n.global.t('Freight Method'), value: 'freightMethod' },
  { name: i18n.global.t('Truck Freight'), value: 'freight' },
  { name: i18n.global.t('PR'), value: 'pr' },
  { name: i18n.global.t('OR'), value: 'or' },
  { name: i18n.global.t('Total Freight'), value: 'totalAmount' },
]

export const truckerBasicInfoColumns = [
  { name: i18n.global.t('Date'), value: 'loadingDate' },
  { name: i18n.global.t('Loading Points'), value: 'loadingPoints' },
  { name: i18n.global.t('Unloading Points'), value: 'unloadingPoints' },
  { name: i18n.global.t('Capacity / No. of Units'), value: 'capacity' },
  { name: i18n.global.t('Freight Method'), value: 'freightMethod' },
  { name: i18n.global.t('Truck Freight'), value: 'freight' },
  { name: i18n.global.t('CROSSING'), value: 'crossing' },
  { name: i18n.global.t('Total Freight'), value: 'totalAmount' },
]

export const loaderBasicInfoColumns = [
  { name: i18n.global.t('Date'), value: 'loadingDate' },
  { name: i18n.global.t('Loading Points'), value: 'loadingPoints' },
  { name: i18n.global.t('Unloading Points'), value: 'unloadingPoints' },
  { name: i18n.global.t('Capacity / No. of Units'), value: 'capacity' },
  { name: i18n.global.t('Freight Method'), value: 'freightMethod' },
  { name: i18n.global.t('Truck Freight'), value: 'freight' },
  { name: i18n.global.t('PR'), value: 'pr' },
  { name: i18n.global.t('Total Freight'), value: 'totalAmount' },
]

export const loaderTotalPayment = {
  freightMethod: 'freightMethod',
  capacity: 'capacity',
  totalFreight: 'totalFreight',
  freightReceiveAdmin: 'freightReceiveAdmin',
}

export const paymentStructure = [
  { title: i18n.global.t('Advance Payment'), value: 'advanceAmount' },
  { title: i18n.global.t('To Pay Amount'), value: 'toPayAmount' },
  { title: i18n.global.t('POD Amount'), value: 'podAmount' },
]

export const truckerFreightMethod = ['freightMethod', 'freightValue', 'crossing', 'totalFreight']

export const truckerPaymentToPay = [
  'crossing', 'commission', 'crossingCommission',
]

export const confirmationLoaderInfoColumns = [
  { name: i18n.global.t('Load ID'), value: 'id' },
  { name: i18n.global.t('Primary Name'), value: 'fullname' },
  { name: i18n.global.t('Business Name'), value: 'businessName' },
  { name: i18n.global.t('Loader ID'), value: 'loaderId' },
  { name: i18n.global.t('Primary Mobile No'), value: 'preferredContact' },
  { name: i18n.global.t('Trading Name'), value: 'tradingName' },
  { name: i18n.global.t('Industry'), value: 'industry' },
  { name: i18n.global.t('Alternative Name'), value: 'altContact' },
  { name: i18n.global.t('Business Area'), value: 'placeName' },
  { name: i18n.global.t('Materials/Products'), value: 'materials' },
  { name: i18n.global.t('Alternative Mobile No'), value: 'altContactMobile' },
  { name: i18n.global.t('Email Address'), value: 'email' },
  { name: i18n.global.t('Conditions'), value: 'conditions' },
  { name: 'Load Availability Date', value: 'loadingDate' },
  { name: i18n.global.t('Notes'), value: 'notes' },
]

export const invoiceLoaderInfoColumns = [
  { name: i18n.global.t('Load ID'), value: 'id' },
  { name: i18n.global.t('Primary Name'), value: 'fullname' },
  { name: i18n.global.t('Business Name'), value: 'businessName' },
  { name: i18n.global.t('Loader ID'), value: 'loaderId' },
  { name: i18n.global.t('Primary Mobile No'), value: 'preferredContact' },
  { name: i18n.global.t('Trading Name'), value: 'tradingName' },
  { name: i18n.global.t('Industry'), value: 'industry' },
  { name: i18n.global.t('Alternative Name'), value: 'altContact' },
  { name: i18n.global.t('Address'), value: 'address' },
  { name: i18n.global.t('Materials/Products'), value: 'materials' },
  { name: i18n.global.t('Alternative Mobile No'), value: 'altContactMobile' },
  { name: i18n.global.t('Business Area'), value: 'placeName' },
  { name: i18n.global.t('Conditions'), value: 'conditions' },
  { name: i18n.global.t('Notes'), value: 'notes' },
  { name: i18n.global.t('Email Address'), value: 'email' },
]

export const confirmationTruckerInfoColumns = [
  { name: i18n.global.t('Offer ID'), value: 'id' },
  { name: i18n.global.t('Primary Name'), value: 'fullname' },
  { name: i18n.global.t('Business Name'), value: 'businessName' },
  { name: i18n.global.t('Trucker ID'), value: 'truckerId' },
  { name: i18n.global.t('Primary Mobile No'), value: 'ownerContact' },
  { name: i18n.global.t('Trading Name'), value: 'tradingName' },
  { name: i18n.global.t('Pref. Routes'), value: 'preferredRoutes' },
  { name: i18n.global.t('Alternative Name'), value: 'alternateName' },
  { name: i18n.global.t('Business Area'), value: 'placeName' },
  { name: i18n.global.t('Pref. Materials/Products'), value: 'materials' },
  { name: i18n.global.t('Alternative Mobile No'), value: 'altContactMobile' },
  { name: i18n.global.t('Email Address'), value: 'email' },
  { name: i18n.global.t('Truck No.'), value: 'truckNumber' },
  { name: i18n.global.t('Driver Name'), value: 'driverName' },
  { name: i18n.global.t('Driver License No.'), value: 'licenseNumber' },
  { name: i18n.global.t('Tyres'), value: 'tyres' },
  { name: i18n.global.t('Driver Mobile No.'), value: 'driverMobile' },
  { name: i18n.global.t('Truck Available Area'), value: 'offerPlaceName' },
  { name: i18n.global.t('Capacity'), value: 'capacity' },
  { name: i18n.global.t('Chassis No.'), value: 'chassisNumber' },
  { name: i18n.global.t('Engine No.'), value: 'engineNumber' },
  { name: i18n.global.t('Conditions'), value: 'conditions' },
  { name: 'Offer Availability Date', value: 'loadingDate' },
  { name: i18n.global.t('Notes'), value: 'notes' },
]

export const loadingPointColumns = [
  { name: i18n.global.t('Business Name'), value: 'businessName' },
  { name: i18n.global.t('Area'), value: 'placeName' },
  { name: i18n.global.t('Capacity'), value: 'capacity' },
  { name: i18n.global.t('Loading Charges'), value: 'charges' },
  { name: 'Full Name', value: 'fullname' },
  { name: 'Designation', value: 'designation' },
  { name: 'Email Address', value: 'email' },
  { name: i18n.global.t('Address'), value: 'address' },
  { name: i18n.global.t('Preferred Contact No.'), value: 'mobile' },
  { name: 'Landline No.', value: 'landline' },
]

export const unloadingPointColumns = [
  { name: i18n.global.t('Business Name'), value: 'businessName' },
  { name: i18n.global.t('Area'), value: 'placeName' },
  { name: i18n.global.t('Capacity'), value: 'capacity' },
  { name: i18n.global.t('Unloading Charges'), value: 'charges' },
  { name: 'Full Name', value: 'fullname' },
  { name: 'Designation', value: 'designation' },
  { name: 'Email Address', value: 'email' },
  { name: i18n.global.t('Address'), value: 'address' },
  { name: i18n.global.t('Preferred Contact No.'), value: 'mobile' },
  { name: 'Landline No.', value: 'landline' },
]

export const loadingPointsDialogColumns = [
  { name: i18n.global.t('Business Name'), value: 'businessName' },
  { name: i18n.global.t('Loading Point'), value: 'placeName' },
  { name: i18n.global.t('Mobile No.'), value: 'preferredContact' },
  { name: i18n.global.t('Charges'), value: 'charges' },
]

export const unloadingPointsDialogColumns = [
  { name: i18n.global.t('Business Name'), value: 'businessName' },
  { name: i18n.global.t('Unloading Point'), value: 'placeName' },
  { name: i18n.global.t('Mobile No.'), value: 'preferredContact' },
  { name: i18n.global.t('Charges'), value: 'charges' },
]

export const podDocumentUpload = [
  { label: 'POD Amount', value: '' },
  { label: 'Invoice', value: '' },
  { label: 'Challan', value: '' },
  { label: 'Document', value: '' },
]

export const podDocuments = {
  isPhyPodReceivedTrucker: '1. Physical POD documents received from trucker',
  isPhyPodSubmittedLoader: '2. Physical POD documents submitted to loader',
  isPodReceivedLoader: '3. POD amount received from loader',
  isPodPaidTrucker: '4. POD amount paid to trucker',
}

export const documentUpload = [
  { label: 'Way Bill', value: '' },
  { label: 'Invoice', value: '' },
  { label: 'Document:', value: '' },
]

export const truckerDocuments = [
  { label: 'Insurance' },
  { label: 'License' },
  { label: 'RC Book' },
  { label: 'NOC Letter' },
]

export const paymentInfoHeaders = [
  { name: 'Bank Name', value: 'bankName', key: 'bankName' },
  { name: 'Account No.', value: 'accountNo', key: 'bankNumber' },
  { name: 'Account Name', value: 'accountName', key: 'accountName' },
  { name: 'IFSC Code', value: 'ifscCode', key: 'bankIfscCode' },
  { name: 'Branch Name', value: 'branchName', key: 'bankBranchAddress' },
  { name: 'Account Type', value: 'accountType', key: 'bankType' },
]

export const usersDashboardHeaders = [
  { title: 'Route', key: 'route', sortable: false },
  { title: 'Total Available', key: 'total_available', align: 'start' },
  { title: 'Sizes', key: 'sizes', align: 'start', sortable: false },
  { title: 'Market', key: 'market', align: 'start', sortable: false },
  { title: 'Available', key: 'available', align: 'start', sortable: false },
  { title: 'Truck No.', key: 'truck_no', align: 'start', sortable: false },
  { title: 'No. of CNF', key: 'no_of_cnf', align: 'end', sortable: false },
]

export const smsOrWhatsappLogsHeaders = [
  { title: i18n.global.t('general.campaign'), key: 'campaign', sortable: false },
  { title: i18n.global.t('reports.template_name'), key: 'template_name', sortable: false },
  { title: i18n.global.t('reports.template_id'), key: 'template_id', align: 'start', sortable: false },
  { title: i18n.global.t('reports.template'), key: 'template', sortable: false },
  { title: i18n.global.t('Date & Time'), key: 'date_time', align: 'start', sortable: false },
  { title: i18n.global.t('reports.total_counts'), key: 'total_counts', align: 'start', sortable: false },
  { title: i18n.global.t('reports.sms_status'), key: 'status', align: 'center', sortable: false },
]

export const transactionsHeaders = [
  { title: 'ID', key: 'id', sortable: false },
  { title: 'Transaction ID', key: 'transactionId', sortable: false },
  { title: 'Transaction Date', key: 'createdAt', align: 'start' },
  { title: 'CNF ID', key: 'cnfId', sortable: false },
  { title: 'Description', key: 'description', sortable: false },
  { title: 'Amount', key: 'amount' },
  { title: 'Status', key: 'status', sortable: false },
]

export const auditLogsHeaders = [
  { title: 'Module', key: 'id', sortable: false },
  { title: 'Role', key: 'role' },
  { title: 'Email', key: 'email', sortable: false },
  { title: 'Event', key: 'name' },
  { title: 'Created At', key: 'created_at', align: 'start' },
  { title: 'Details', key: 'details', align: 'center' },
]

export const globalSearchLoaderTruckerHeaders = [
  { title: 'ID', key: 'id', sortable: false },
  { title: 'Business Name', key: 'businessName' },
  { title: 'Trading Name', key: 'tradingName' },
  { title: 'Primary Name', key: 'fullname' },
  {
    title: 'Mobile',
    key: 'mobiles',
    align: 'start',
    sortable: false,
  },

  {
    title: 'Landline',
    key: 'landlines',
    align: 'start',
    sortable: false,

    render(item: any) {
      return item?.[0]?.value
    },
  },
  { title: 'Actions', key: 'actions', sortable: false, align: 'center' },
]

export const globalSearchEmployeeHeaders = [
  { title: 'ID', key: 'id', sortable: false },
  { title: 'Business Name', key: 'employable_id.businessName' },
  { title: 'Trading Name', key: 'employable_id.tradingName' },
  { title: 'Employee Name', key: 'fullname' },
  {
    title: 'Mobile',
    key: 'mobiles',
    align: 'start',
    sortable: false,
  },

  {
    title: 'Landline',
    key: 'landlines',
    align: 'start',
    sortable: false,

    render(item: any) {
      return item?.[0]?.value
    },
  },
  { title: 'Actions', key: 'actions', sortable: false, align: 'center' },
]

export const deleteUserRequestsHeaders = [
  { title: i18n.global.t('delete_users.search.id'), key: 'id', sortable: false },
  { title: i18n.global.t('delete_users.search.email'), key: 'email', align: 'start', sortable: true },
  { title: i18n.global.t('delete_users.search.mobile'), key: 'mobile', align: 'start', sortable: true },
  { title: i18n.global.t('delete_users.search.role'), key: 'userType', align: 'start', sortable: true },
  { title: i18n.global.t('delete_users.search.delete_type'), key: 'deleteType', align: 'start', sortable: true },
  { title: i18n.global.t('delete_users.search.status'), key: 'status', align: 'start', sortable: true },
]

export const globalSearchDriverHeaders = [
  { title: 'ID', key: 'id', sortable: false },
  { title: 'Business Name', key: 'employable_id.businessName' },
  { title: 'Trading Name', key: 'employable_id.tradingName' },
  { title: 'Driver Name', key: 'fullname' },
  {
    title: 'Mobile',
    key: 'mobiles',
    align: 'start',
    sortable: false,
  },

  {
    title: 'Landline',
    key: 'landlines',
    align: 'start',
    sortable: false,

    render(item: any) {
      return item?.[0]?.value
    },
  },
  { title: 'Actions', key: 'actions', sortable: false, align: 'center' },
]

export const loadingPointHeader = [
  { title: 'Select', key: 'select', sortable: false, width: '5%,', fieldKey: 'select' },
  { title: 'Business Name', key: 'businessName', sortable: false, width: '10%', fieldKey: 'businessName' },
  { title: 'Address', key: 'address', sortable: false, width: '10%', fieldKey: 'address' },
  { title: 'Place Name', key: 'placeName', sortable: false, width: '10%', fieldKey: 'location' },
  { title: 'Material', key: 'material', sortable: false, width: '10%', fieldKey: 'material' },
  {
    title: 'Capacity',
    key: 'capacity',
    sortable: false,
    width: '10%',
    fieldKey: 'capacity',
  },
  { title: 'Freight', key: 'totalAmount', sortable: false, width: '10%', fieldKey: 'totalFreight' },
  { title: 'Preferred Name', key: 'fullname', sortable: false, width: '10%', fieldKey: 'contactName' },
  { title: 'Contact No.', key: 'preferredContact', sortable: false, width: '10%', fieldKey: 'contactNumber' },
  { title: 'GST No.', key: 'gstNumber', sortable: false, width: '10%', fieldKey: 'gstNumber' },
  { title: 'Actions', key: 'actions', sortable: false, width: '5%', fieldKey: 'actions' },
]

export const unloadingPointHeader = [
  { title: 'Select', key: 'select', sortable: false, width: '5%', fieldKey: 'select' },
  { title: 'Business Name', key: 'businessName', sortable: false, width: '10%', fieldKey: 'businessName' },
  { title: 'Address', key: 'address', sortable: false, width: '10%', fieldKey: 'address' },
  { title: 'Place Name', key: 'placeName', sortable: false, width: '10%', fieldKey: 'location' },
  { title: 'Material', key: 'material', sortable: false, width: '10%', fieldKey: 'material' },
  {
    title: 'Capacity',
    key: 'capacity',
    sortable: false,
    width: '10%',
    fieldKey: 'capacity',
  },
  { title: 'Freight', key: 'totalAmount', sortable: false, width: '10%', fieldKey: 'totalFreight' },
  { title: 'Preferred Name', key: 'fullname', sortable: false, width: '10%', fieldKey: 'contactName' },
  { title: 'Contact No.', key: 'preferredContact', sortable: false, width: '10%', fieldKey: 'contactNumber' },
  { title: 'GST No.', key: 'gstNumber', sortable: false, width: '10%', fieldKey: 'gstNumber' },
  { title: 'Actions', key: 'actions', sortable: false, width: '5%', fieldKey: 'actions' },
]

export const editLoadingInvoiceHeader = [
  { title: 'Select', key: 'select', sortable: false, width: '5%', fieldKey: 'select' },
  { title: 'Business Name', key: 'businessName', sortable: false, width: '10%', fieldKey: 'businessName' },
  { title: 'Address', key: 'address', sortable: false, width: '10%', fieldKey: 'address' },
  { title: 'Place Name', key: 'location', sortable: false, width: '10%', fieldKey: 'location' },
  { title: 'Material', key: 'material', sortable: false, width: '10%', fieldKey: 'material' },
  { title: 'Capacity', key: 'capacity', sortable: false, width: '10%', fieldKey: 'capacity' },
  { title: 'Freight', key: 'totalFreight', sortable: false, width: '10%', fieldKey: 'totalFreight' },
  { title: 'Preferred Name', key: 'contactName', sortable: false, width: '10%', fieldKey: 'contactName' },
  { title: 'Contact No.', key: 'contactNumber', sortable: false, width: '10%', fieldKey: 'contactNumber' },
  { title: 'GST No.', key: 'gstNumber', sortable: false, width: '10%', fieldKey: 'gstNumber' },
  { title: 'Actions', key: 'actions', sortable: false, width: '5%', fieldKey: 'actions' },
]

export const editUnloadingInvoiceHeader = [
  { title: 'Select', key: 'select', sortable: false, width: '5%', fieldKey: 'select' },
  { title: 'Business Name', key: 'businessName', sortable: false, width: '10%', fieldKey: 'businessName' },
  { title: 'Address', key: 'address', sortable: false, width: '10%', fieldKey: 'address' },
  { title: 'Place Name', key: 'location', sortable: false, width: '10%', fieldKey: 'location' },
  { title: 'Material', key: 'material', sortable: false, width: '10%', fieldKey: 'material' },
  { title: 'Capacity', key: 'capacity', sortable: false, width: '10%', fieldKey: 'capacity' },
  { title: 'Freight', key: 'totalFreight', sortable: false, width: '10%', fieldKey: 'totalFreight' },
  { title: 'Preferred Name', key: 'contactName', sortable: false, width: '10%', fieldKey: 'contactName' },
  { title: 'Contact No.', key: 'contactNumber', sortable: false, width: '10%', fieldKey: 'contactNumber' },
  { title: 'GST No.', key: 'gstNumber', sortable: false, width: '10%', fieldKey: 'gstNumber' },
  { title: 'Actions', key: 'actions', sortable: false, width: '5%', fieldKey: 'actions' },
]

export const contactEnquiriesHeaders = [
  { title: 'ID', key: 'id', sortable: false },
  { title: 'Name', key: 'name', align: 'start', sortable: false },
  { title: 'Contact No.', key: 'contactNo', align: 'start', sortable: false },
  { title: 'Email', key: 'email', align: 'start', sortable: false },
  { title: 'Subject', key: 'subject', sortable: false },
  { title: 'Message', key: 'message', sortable: false },
  { title: 'Created At', key: 'createdAt', sortable: false },
]
