/* eslint-disable import/no-mutable-exports */
/* eslint-disable prefer-const */
/* eslint-disable sonarjs/cognitive-complexity */
import { capitalized, extractCityState, formatDateWithoutTime, getFormattedAmount, isConfidential, isValue } from '@/helpers'
import type { ISearchUsers } from '@/interface'
import i18n from '@/plugins/i18n/index'
import { can } from '@layouts/plugins/casl'

export const isAdmin = computed(() => can('manage', 'all'))

export const isLoader = computed(() => can('manage', 'User'))

export const isTrucker = computed(() => can('manage', 'User'))

export const usersTypesOptions = ['Loader', 'Trucker']

export const userAbilitiesByType: any = {
  admin: { action: 'manage', subject: 'all' },
  loader: { action: 'manage', subject: 'User' },
  trucker: { action: 'manage', subject: 'User' },
}

export const businessCluster = [i18n.global.t('users.all_clusters')]
export const products = [i18n.global.t('users.all_products')]
export const industries = [i18n.global.t('users.all_industries')]
export const verification = [{ text: i18n.global.t('general.verified'), value: '1' },
  { text: i18n.global.t('general.unverified'), value: '0' },
  { text: i18n.global.t('general.all'), value: '' }]

export const activeOptions = [
  { text: i18n.global.t('Active'), value: '1' },
  { text: i18n.global.t('Inactive'), value: '0' },
  { text: i18n.global.t('general.all'), value: '' },
]

// This numbers are of tyres of truck
export const truckTyres = [
  { text: '4', value: '4' },
  { text: '6', value: '6' },
  { text: '10', value: '10' },
  { text: '12', value: '12' },
  { text: '14', value: '14' },
  { text: '16', value: '16' },
  { text: '18 and above', value: '18' },
  { text: 'All', value: '' },
]

export const offerSuggestionTyres = [
  { text: '4', value: '4' },
  { text: '6', value: '6' },
  { text: '10', value: '10' },
  { text: '12', value: '12' },
  { text: '14', value: '14' },
  { text: '16', value: '16' },
  { text: '18 and above', value: '18' },
  { text: 'All', value: 'all' },
]

export const truckCapacity = [
  { text: '1-5 tons', value: '4' },
  { text: '6-16 tons', value: '5' },
  { text: '17-21 tons', value: '0' },
  { text: '22-25 tons', value: '1' },
  { text: '26-30 tons', value: '2' },
  { text: '31-35 tons', value: '3' },
  { text: '36-50 tons', value: '6' },
  { text: 'All', value: '' },
]

export const loadSuggestionCapacity = [
  { text: '1-5 tons', value: '4' },
  { text: '6-16 tons', value: '5' },
  { text: '17-21 tons', value: '0' },
  { text: '22-25 tons', value: '1' },
  { text: '26-30 tons', value: '2' },
  { text: '31-35 tons', value: '3' },
  { text: '36-50 tons', value: '6' },
  { text: 'All', value: 'all' },
]

export const addTruckCapacity = [
  { text: '1-5 tons', value: '4' },
  { text: '6-16 tons', value: '5' },
  { text: '17-21 tons', value: '0' },
  { text: '22-25 tons', value: '1' },
  { text: '26-30 tons', value: '2' },
  { text: '31-35 tons', value: '3' },
  { text: '36-50 tons', value: '6' },
]

export const truckTyreCapacity = [
  { id: '4', tyre: '4', value: '1-5 tons', tyreTitle: '4' },
  { id: '5', tyre: '6', value: '6-16 tons', tyreTitle: '6' },
  { id: '0', tyre: '10', value: '17-21 tons', tyreTitle: '10' },
  { id: '1', tyre: '12', value: '22-25 tons', tyreTitle: '12' },
  { id: '2', tyre: '14', value: '26-30 tons', tyreTitle: '14' },
  { id: '3', tyre: '16', value: '31-35 tons', tyreTitle: '16' },
  { id: '6', tyre: '18', value: '36-50 tons', tyreTitle: '18 and above' },
]

export const visibility = [
  { text: i18n.global.t('general.public'), value: '1' },
  { text: i18n.global.t('general.private'), value: '0' },
]

export const offerOrLoadVisibility = [
  { text: i18n.global.t('general.private'), value: 0 },
  { text: i18n.global.t('general.public'), value: 1 },
]

export const loadsStatus = [{ text: 'Expired', value: 'Expired' },
  { text: 'Available', value: 'Available' },
  { text: 'Expiring', value: 'Expiring' },
  { text: 'In process', value: 'In process' },
  { text: 'Cancelled', value: 'Cancelled' },
  { text: 'All', value: '' }]

export const loadsFreight = [
  { text: 'Per Ton', value: 'per_ton' },
  { text: 'Per Unit', value: 'per_unit' },
  { text: 'Total Freight', value: 'total_freight' },
  { text: 'All', value: '' },
]

export const bankTypes = [i18n.global.t('bank.saving_account'),
  i18n.global.t('bank.current_account'),
  i18n.global.t('bank.salary_account'),
  i18n.global.t('bank.fixed_account'),
  i18n.global.t('bank.recurring_account'),
  i18n.global.t('bank.nri_account')]

export const smsTemplate = ['SMS Sample Static template']

export const exportType = [{ title: 'Daily', value: 'daily' }, { title: 'Weekly', value: 'weekly' }, { title: 'Month', value: 'month' }, { title: 'Yearly', value: 'yearly' }, { title: 'Custom', value: 'custom' }]

export const usersColumnsBold = [i18n.global.t('users.business_name'), i18n.global.t('users.business_area'), i18n.global.t('users.trading_name'), i18n.global.t('users.business_cluster')]

export const usersSelectOptions = [
  i18n.global.t('general.active'),
  i18n.global.t('general.inactive'),
  i18n.global.t('general.verified'),
  i18n.global.t('general.unverified'),

  // i18n.global.t('general.delete'), => temporarily committed as per the requirement
]

export const usersSelectedOptions = [i18n.global.t('general.available'), i18n.global.t('general.in_process'), i18n.global.t('general.public'), i18n.global.t('general.private'), i18n.global.t('general.cancel'), i18n.global.t('general.delete')]

export const selectedOptionsLoaderTrucker = [i18n.global.t('general.cancel'), i18n.global.t('general.delete')]

// admin status
export const usersSelectedOptionsWithDisable = [
  {
    text: 'Available',
    value: 'Available',
    disabled: true,
  },
  {
    text: 'In process',
    value: 'In process',
    disabled: true,
  },
  {
    text: 'Public',
    value: 'Public',
    disabled: true,
  },
  {
    text: 'Private',
    value: 'Private',
    disabled: true,
  },
  {
    text: 'Cancel',
    value: 'Cancel',
    disabled: true,
  },
  {
    text: 'Delete',
    value: 'Delete',
  },
]

// Loader and trucker Status
export const selectOptionsWithDisableLoaderTrucker = [

  {
    text: 'Cancel',
    value: 'Cancel',
    disabled: true,
  },
  {
    text: 'Delete',
    value: 'Delete',
    disabled: true,
  },
]

export const bulkUsersStatus = {
  available: 'Available',
  inProcess: 'In process',
  public: 'Public',
  private: 'Private',
  cancel: 'Cancel',
  delete: 'Delete',
  reject: 'Reject',
  decline: 'decline',
}

export const isActionStatus = {
  available: 'Available',
  inProcess: 'In process',
}

export const moduleType = {
  confirmation: 'confirmation',
  quickConfirmation: 'quick-confirmation',
  auditLogs: 'Audit Logs',
}

export const usersSubTypesSelectOptions = [i18n.global.t('general.active'), i18n.global.t('general.inactive'), i18n.global.t('general.delete')]

export const freightMethodTypes: any = {
  per_ton: i18n.global.t('Per Ton'),
  per_unit: i18n.global.t('Per Unit'),
  total_freight: i18n.global.t('Total Freight'),
}

export const showFreightMethodTypes: any = {
  per_ton: i18n.global.t('Ton'),
  Tons: i18n.global.t('Ton'),
  per_unit: i18n.global.t('Unit'),
  Units: i18n.global.t('Unit'),
  total_freight: i18n.global.t('Freight'),
}

export const confirmationMenuList = [
  { title: 'Audit Logs', value: 'Audit Logs' },
  { title: 'Confirmation Link', value: 'Confirmation Link' },
  { title: 'SMS Sent Log', value: 'SMS Sent Report' },
  { title: 'Invoices', value: 'Invoices' },
]

export const loadColumn = {
  booked: 'load_booked',
}

export const hasStatusType = {
  active: 'active',
  inactive: 'inactive',
}

export const userActions = {
  add: 'add',
  view: 'view',
  log: 'log',
  edit: 'edit',
  delete: 'delete',
  status: 'status',
  verify: 'verify',
  doc_verify: 'doc_verify',
  cancel: 'cancelled',
  available: 'available',
  inProcess: 'in process',
  public: 'public',
  private: 'private',
  lock: 'lock',
  reload: 'reload',
  repeat: 'repeat',
  unlock: 'unlock',
  booked: 'booked',
  in_process: 'In process',
  Available: 'Available',
  Expiring: 'Expiring',
  Expired: 'Expired',
  Cancelled: 'Cancelled',
  Cancel: 'Cancel',
  Delete: 'Delete',
  docVerify: 'docVerify',
}

export const userActionType = {
  default: 'default',
  view: 'view',
}

export const amountTypes = [
  { title: 'Paid', value: 'Paid' },
  { title: 'Received', value: 'Received' },
]

export const transactionsType = [
  { title: 'Razor Pay', value: 'RAZORPAY' },
  { title: 'A/C Transfer', value: 'NETBANKING' },
  { title: 'Cash', value: 'CASH' },
]

export const createLedgerTransactionType = [
  { title: 'A/C Transfer', value: 'NETBANKING' },
  { title: 'Cash', value: 'CASH' },
]

export const bannerUserTypeOptions = [{ text: 'Loader', value: 'loader' },
  { text: 'Trucker', value: 'trucker' },
  { text: 'Both', value: 'both' }]

export const loaderPaidToOptions = ['Navodaya', 'Trucker']

export const truckerPaidToOptions = ['Navodaya', 'Loader']

export const ledgerAmountTypes = [
  { text: 'Freight', value: 'FREIGHT' },
  { text: 'POD', value: 'POD' },
  { text: 'PR', value: 'PR' },
  { text: 'Crossing + Commission', value: 'CROSSING_COMMISSION' },
]

export const tabsTitle = {
  basicInfo: 'basic-info',
  docUpload: 'doc-upload',
  offerLoadContact: 'offer-load-contact',
  offerLoad: 'offer-load',
}

export const quickConfirmationTabs = {
  loaderInfo: 'loader-info',
  truckerInfo: 'trucker-info',
  paymentInfo: 'payment-info',
}

export const confirmationTabs = {
  basicInfo: 'basic-info',
  paymentInfo: 'payment-info',
}

export const usersType = {
  loader: 'loader',
  trucker: 'trucker',
  drivers: 'drivers',
  trucks: 'trucks',
  banks: 'banks',
  routes: 'routes',
  offers: 'offers',
  ledger: 'ledger',
  loaders: 'loaders',
  truckers: 'truckers',
  employees: 'employees',
  driver: 'driver',
  employee: 'employee',
  branches: 'branches',
  loads: 'loads',
  mcBook: 'mc-book',
  admin: 'admin',
  offer: 'offer',
  load: 'load',
  addNewOffer: 'Offer',
  addNewLoad: 'Load',
  loadBoard: 'load-board',
  truckBoard: 'offer-board',
  users: 'users',
  confirmation: 'confirmation',
  loadSuggestions: 'loadSuggestions',
  pod: 'pod',
}
export const docsType = {
  documents: 'documents',
  columns: {
    reason: 'reason',
  },
}
export const docsStatus = {
  approved: 'Approved',
  rejected: 'Rejected',
  expiring: 'Expiring',
}
export const userTableOptions = reactive({
  page: 1,
  results: 10,
  sortField: '',
  sortOrder: '',
  search: false,
})

export const userDocTableOptions = reactive({
  page: 1,
  results: 100,
  sortField: '',
  sortOrder: '',
  search: true,
})

export const usersData = {
  id: '',
  business_name: '',
  trading_name: '',
  address: '',
  fullname: null,
  phone: '',
  mobiles: [''],
  landlines: [''],
  email: '',
  gst: '',
  notes: '',
  pan: '',
  place_id: '',
  place_name: '',
  place_latitude: '',
  place_longitude: '',
  place_reference: '',
  designation: '',
  website: '',
  aadhar: '',
  type: 'trucker',
  materials: [],
  industry: '',
}

export const driverInfo = reactive({
  address: '',
  designation: '',
  email: '',
  fullname: '',
  landlines: [],
  license_number: '',
  mobiles: [],
})

export const offerInfo = reactive({
  primary_contact: {
    fullname: '',
    designation: '',
    email: '',
    mobiles: [],
    landlines: [],
  },
  alternative_contact: {
    fullname: '',
    designation: '',
    email: '',
    mobiles: [],
    landlines: [],
  },
})

export const offerDetails = reactive({
  truckNo: '',
  chassisNo: '',
  tyres: '',
  capacity: '',
  engineNo: '',
  availableArea: '',
  loadingDate: '',
  material: [],
  routes: [],
  notes: '',
  public: '',
  status: '',
  driver_details: {
    fullname: '',
    designation: '',
    email: '',
    mobiles: [],
    landlines: [],
  },
})

export const isPublic = [
  { id: '1', name: 'Yes' },
  { id: '0', name: 'No' },
]

export const isReceiving = ['Yes', 'No']

export const commissionOptions = ['MC Book', 'Cash', 'A/C Transfer']

export const availability = [
  { id: 'Available', name: 'Available' },
  { id: 'In process', name: 'In process' },
]

export const loaderTruckerAvailability = [
  { id: 'In process', name: 'In process' },
]

export let truckInfo = reactive({
  capacity: '',
  chassis_number: '',
  engine_number: '',
  routes: [],
  truck_number: '',
  tyres: null,
  truck_length: '',
  l: null,
  w: null,
  h: null,
})

export const isPrimary = [
  { text: i18n.global.t('Yes'), value: 1 },
  { text: i18n.global.t('No'), value: 0 },
]

export let bankInfo = reactive({
  bank_name: '',
  bank_number: '',
  bank_ifsc_code: '',
  bank_branch_address: '',
  bank_type: null,
  bank_pan_number: '',
  account_name: '',
  is_default: isPrimary.at(0)?.value,
})

export const employeeInfo = {
  designation: '',
  email: '',
  fullname: '',
  landlines: [],
  mobiles: [],
}

export const branchInfo = {
  designation: '',
  email: '',
  fullname: '',
  landlines: [],
  mobiles: [],
  business_name: '',
  trading_name: '',
  address: '',
  gst: '',
  charges: '',
  notes: '',
  pan: '',
  place_name: '',
  website: '',
  aadhar: '',
  materials: [],
  industry: null,
  place_latitude: '',
  place_longitude: '',
}

export const resetBranchInfo = reactive({ ...branchInfo })

export const loadInfo = reactive({
  primary_contact: {
    fullname: '',
    designation: '',
    email: '',
    mobiles: [],
    landlines: [],
  },
  alternative_contact: {
    fullname: '',
    designation: '',
    email: '',
    mobiles: [],
    landlines: [],
  },
})

export const resetData = reactive({ ...usersData })

export const truckersProfileColumns = [
  { name: 'Trucker ID', value: 'id' },
  { name: 'Business Name', value: 'business_name' },
  { name: 'Trading Name', value: 'trading_name' },
  { name: 'Address', value: 'address' },
  { name: 'Business Area', value: 'place_name' },
  { name: 'Business GST IN', value: 'gst' },
  { name: 'PAN No.', value: 'pan' },
  { name: 'Email Id', value: 'user', subValue: 'email' },
  { name: 'Website', value: 'web' },
  { name: 'Designation', value: 'designation' },
  { name: 'Mobile No.', value: 'mobiles' },
  { name: 'Landline No.', value: 'landline' },
]

export const usersOfferProfileColumns = [
  { name: 'Offer ID', value: 'id', is_bold: true },
  { name: 'Business Area', value: 'business_area' },
  { name: 'Business Name', value: 'business_name', is_bold: true },
  { name: 'Trucker ID', value: 'trucker_id' },
  { name: 'Business Cluster', value: 'businessOfferCluster' },
  { name: 'Trading Name', value: 'trading_name', is_bold: true },
  { name: 'Owner Name', value: 'primary', subValue: 'fullname' },
  { name: 'Driver Name', value: 'driver', subValue: 'fullname' },
  { name: 'Truck No', value: 'truck_number' },
  { name: 'Owner Mobile No.', value: 'ownerNo' },
  { name: 'Driver Mobile No.', value: 'driverNo' },
  { name: 'Tyres/Capacity Range', value: 'tyres', is_bold: true },
  { name: 'Alt Name', value: 'alternative', subValue: 'fullname' },
  { name: 'Preferred Routes', value: 'routes' },
  { name: 'Available Area', value: 'area', is_bold: true },
  { name: 'Alt Mobile No.', value: 'alternativeNo' },
  { name: 'Pref. Materials / Products', value: 'product', subValue: 'material' },
  { name: 'Available Date', value: 'loading_date' },
  { name: 'Created Timestamp', value: 'created_at' },
  { name: 'Conditions', value: 'conditions' },
  { name: 'Offer Cluster', value: 'clusters', is_bold: true },
  { name: 'Updated Timestamp', value: 'updated_at' },
  { name: 'Notes', value: 'notes' },
  { name: 'Status', value: 'status', is_bold: true },
]

export const adminOfferProfileColumns = [
  { name: 'Offer ID', value: 'id', is_bold: true },
  { name: 'Business Area', value: 'business_area' },
  { name: 'Business Name', value: 'business_name', is_bold: true },
  { name: 'Trucker ID', value: 'trucker_id' },
  { name: 'Business Cluster', value: 'businessOfferCluster' },
  { name: 'Trading Name', value: 'trading_name', is_bold: true },
  { name: 'Owner Name', value: 'primary', subValue: 'fullname' },
  { name: 'Driver Name', value: 'driver', subValue: 'fullname' },
  { name: 'Truck No', value: 'truck_number' },
  { name: 'Owner Mobile No.', value: 'ownerNo' },
  { name: 'Driver Mobile No.', value: 'driverNo' },
  { name: 'Tyres/Capacity Range', value: 'tyres', is_bold: true },
  { name: 'Alt Name', value: 'alternative', subValue: 'fullname' },
  { name: 'Preferred Routes', value: 'routes' },
  { name: 'Available Area', value: 'area', is_bold: true },
  { name: 'Alt Mobile No.', value: 'alternativeNo' },
  { name: 'Pref. Materials / Products', value: 'product', subValue: 'material' },
  { name: 'Available Date', value: 'loading_date' },
  { name: 'Created Timestamp', value: 'created_at' },
  { name: 'Conditions', value: 'conditions' },
  { name: 'Offer Cluster', value: 'clusters', is_bold: true },
  { name: 'Updated Timestamp', value: 'updated_at' },
  { name: '', value: '' },
  { name: 'Status', value: 'status', is_bold: true },
  { name: 'Visibility', value: 'is_public' },
  { name: 'Notes', value: 'notes' },
  { name: '', value: '' },
]

export const adminLoadProfileColumns = [
  { name: 'Load ID', value: 'id', is_bold: true },
  { name: 'Business Area', value: 'area' },
  { name: 'Business Name', value: 'business_name', is_bold: true },
  { name: 'Loader ID', value: 'loader_id' },
  { name: 'Business Cluster', value: 'businessCluster' },
  { name: 'Trading Name', value: 'loader', subValue: 'trading_name', is_bold: true },
  { name: 'Pri Name', value: 'primary', subValue: 'fullname' },
  { name: 'Alt Name', value: 'alternative', subValue: 'fullname' },
  { name: 'FR + PR + OR', value: 'fr_pr_or' },
  { name: 'Pri Mobile No.', value: 'ownerNo' },
  { name: 'Alt Mobile No.', value: 'alternativeNo' },
  { name: 'Adv + To Pay + POD', value: 'adv_pay_pod' },
  { name: 'Loading Mobile No.', value: 'loadingMobileNo', subValue: 'preferred_contact', is_bold: true },
  { name: 'Loading Charges', value: 'loading_charges', subValue: 'charges', is_bold: true },
  { name: 'Loading Points', value: 'loading_points', subValue: 'place_name', is_bold: true },
  { name: 'Unloading Mobile No.', value: 'unloadingMobileNo', subValue: 'preferred_contact', is_bold: true },
  { name: 'Unloading Charges', value: 'unloading_charges', subValue: 'charges', is_bold: true },
  { name: 'Unloading Points', value: 'unloading_points', subValue: 'place_name', is_bold: true },
  { name: 'Conditions', value: 'conditions' },
  { name: 'Freight Method', value: 'freight_method' },
  { name: 'Capacity', value: 'capacity', is_bold: true },
  { name: 'Industry', value: 'industry', subValue: 'industry' },
  { name: 'Materials / Products', value: 'product', subValue: 'material' },
  { name: 'Loading Date', value: 'loading_date' },
  { name: 'Created Timestamp', value: 'created_at' },
  {
    name: 'Targeted Route',
    value: 'targeted_route',
  },
  { name: 'Loading Cluster', value: 'cluster', is_bold: true },
  { name: 'Updated Timestamp', value: 'updated_at' },
  {
    name: 'Total Distance',
    value: 'total_distance',
  },
  { name: 'Status', value: 'status', is_bold: true },
  { name: 'Visibility', value: 'is_public' },
  { name: '', value: '' },
  { name: 'Notes', value: 'notes' },

  // { name: 'Remarks', value: 'remark' },
]

export const usersLoadProfileColumns = [
  { name: 'Load ID', value: 'id', is_bold: true },
  { name: 'Business Area', value: 'area' },
  { name: 'Business Name', value: 'business_name', is_bold: true },
  { name: 'Loader ID', value: 'loader_id' },
  { name: 'Business Cluster', value: 'businessCluster' },
  { name: 'Trading Name', value: 'loader', subValue: 'trading_name', is_bold: true },
  { name: 'Pri Name', value: 'primary', subValue: 'fullname' },
  { name: 'Alt Name', value: 'alternative', subValue: 'fullname' },
  { name: 'FR + PR + OR', value: 'fr_pr_or' },
  { name: 'Pri Mobile No.', value: 'ownerNo' },
  { name: 'Alt Mobile No.', value: 'alternativeNo' },
  { name: 'Adv + To Pay + POD', value: 'adv_pay_pod' },
  { name: 'Loading Mobile No.', value: 'loadingMobileNo', subValue: 'preferred_contact', is_bold: true },
  { name: 'Loading Charges', value: 'loading_charges', subValue: 'charges', is_bold: true },
  { name: 'Loading Points', value: 'loading_points', subValue: 'place_name', is_bold: true },
  { name: 'Unloading Mobile No.', value: 'unloadingMobileNo', subValue: 'preferred_contact', is_bold: true },
  { name: 'Unloading Charges', value: 'unloading_charges', subValue: 'charges', is_bold: true },
  { name: 'Unloading Points', value: 'unloading_points', subValue: 'place_name', is_bold: true },
  { name: 'Conditions', value: 'conditions' },
  { name: 'Freight Method', value: 'freight_method' },
  { name: 'Capacity', value: 'capacity', is_bold: true },
  { name: 'Industry', value: 'industry', subValue: 'industry' },
  { name: 'Materials / Products', value: 'product', subValue: 'material' },
  { name: 'Loading Date', value: 'loading_date' },
  { name: 'Created Timestamp', value: 'created_at' },
  {
    name: 'Targeted Route',
    value: 'targeted_route',
  },
  { name: 'Loading Cluster', value: 'cluster', is_bold: true },
  { name: 'Updated Timestamp', value: 'updated_at' },
  {
    name: 'Total Distance',
    value: 'total_distance',
  },
  { name: 'Status', value: 'status', is_bold: true },

  { name: 'Notes', value: 'notes' },
  { name: '', value: '' },
  { name: '', value: '' },
]

export const loadersProfileColumns = [
  { name: 'Loader ID', value: 'id' },
  { name: 'Business Name', value: 'business_name' },
  { name: 'Trading Name', value: 'trading_name' },
  { name: 'Materials / Products', value: 'materials' },
  { name: 'Business Area', value: 'place_name' },
  { name: 'Business GST IN', value: 'gst' },
  { name: 'Industry', value: 'preferred_industries' },
  { name: 'Email Id', value: 'user', subValue: 'email' },
  { name: 'Website', value: 'web' },
  { name: 'Designation', value: 'designation' },
  { name: 'Mobile No.', value: 'mobiles' },
  { name: 'Landline No.', value: 'landline' },
]

export const profileListType = {
  materials: 'materials',
  industries: 'preferred_industries',
  website: 'web',
  mobiles: 'mobiles',
  landline: 'landline',
  email: 'email',
}

export const truckerTabs = [
  { icon: 'tabler-user', title: 'Drivers', key: 'driver_count', type: 'drivers', msgKey: 'drivers', msgType: 'driver' },
  { icon: 'tabler-truck', title: 'Trucks', key: 'truck_count', type: 'trucks', msgKey: 'trucks', msgType: 'truck' },
  { icon: 'tabler-square-chevron-down', title: 'Offers', key: 'offer_count', type: 'offers', msgKey: 'offers', msgType: 'offer' },
  { icon: 'tabler-building-bank', title: 'Banks', key: 'bank_count', type: 'banks', msgKey: 'banks', msgType: 'bank' },
  { icon: 'tabler-clipboard-text', title: 'Ledgers', type: 'ledger', msgKey: 'ledgers', msgType: 'ledger' },
  { icon: 'tabler-notebook', title: 'MC Books', type: 'mc-book', msgKey: 'mc books', module: 'MC Book', msgType: 'mc book' },
]

export const LoaderTabs = [
  { icon: 'tabler-user', title: 'Employees', key: 'employee_count', type: 'employees', msgKey: 'employees', msgType: 'employee' },
  { icon: 'tabler-truck', title: 'Branches', key: 'branch_count', type: 'branches', msgKey: 'branches', msgType: 'branch' },
  { icon: 'tabler-square-chevron-down', title: 'Loads', key: 'load_count', type: 'loads', msgKey: 'loads', msgType: 'load' },
  { icon: 'tabler-building-bank', title: 'Banks', key: 'bank_count', type: 'banks', msgKey: 'banks', msgType: 'bank' },
  { icon: 'tabler-clipboard-text', title: 'Ledgers', type: 'ledger', msgKey: 'ledgers', msgType: 'ledger' },
  { icon: 'tabler-notebook', title: 'MC Books', type: 'mc-book', msgKey: 'mc books', module: 'MC Book', msgType: 'mc book' },
]

export const truckerSearchFields: any = reactive({
  isActive: null,
  id: '',
  fullname: '',
  fullName: '',
  mobiles: '',
  mobile: '',
  landline: '',
  landlines: '',
  email: '',
  designation: '',
  truck_number: '',
  chassis_number: '',
  tyres: '',
  capacity: '',
  engine_number: '',
  primary_fullname: '',
  ownerMobileNo: '',
  driver_fullname: '',
  cluster_place_name: null,
  availableArea: '',
  availableDate: '',
  status: '',
  visibility: '',
  accountName: '',
  bankName: '',
  bankNumber: '',
  bankIfscCode: '',
  bankBranchAddress: '',
  bankType: null,
  bankPanNumber: '',
  is_docs_verified: '',
  confirmationId: '',
  remark: null,
  route_place_name: null,
  description: '',
  paymentType: null,
  reference: '',
  businessName: '',
  trading_name: '',
  place_name: '',
  charges: '',
  routes: null,
  material: null,
  alternative_fullname: '',
  preferred_routes: '',
  available_date: '',
  preferred_contact: '',
  preferred_materials: null,
  alternative_mobiles: '',
  loading_place_name: '',
  unloading_place_name: '',
  load_material_industry: null,
  industry_load_material_industry: null,
  freight_per_ton: '',
  loading_charges: '',
  loading_date: null,
  unloading_charges: '',
  freight_method: null,
  conditions: null,
  is_public: null,
  business_area: '',
  loading_mobiles: '',
  unloading_mobiles: '',
  truckerId: '',
  preferred_route: '',
  locked: '',
  is_lock: null,
  length: '',
  primary_preferred_contact: '',
  driver_preferred_contact: '',
  toDate: null,
  fromDate: null,
  amountType: null,
  paymentMethod: null,
  workableType: null,
  is_active: '',
  gst: null,
  website: null,
  targeted_route_id: '',
  license_number: '',
})

export const resetTruckerFields = { ...truckerSearchFields }

export const isLocked = [
  { text: i18n.global.t('Yes'), value: 'yes' },
  { text: i18n.global.t('No'), value: 'no' },
]

export const offersBoldColumns = ['Offer Cluster', 'Truck No', 'Tyres', 'Available Area']

export const tabType = {
  drivers: 'drivers',
  trucks: 'trucks',
  banks: 'banks',
  employees: 'employees',
  branches: 'branches',
  loads: 'loads',
  offers: 'offers',
  mcBook: 'mc-book',
  ledger: 'ledger',
}

export const usersBulkStatus = {
  active: 'Active',
  inActive: 'Inactive',
  verified: 'Verified',
  unverified: 'Unverified',
  delete: 'Delete',
  docVerified: 'Doc Verified',
  docUnverified: 'Doc Unverified',
}

export const usersTooltip = {
  active: 'Active',
  inActive: 'Inactive',
}

export const acceptedFiles = ['.jpg', '.jpeg', '.png', '.pdf']

export const LoaderTruckerColumnName = {
  maxLoadingCluster: 'max_loading_cluster',
  maxUnloadingRoute: 'max_unloading_route',
  maxTraveledCluster: 'max_travelled_cluster',
}

export const usersDefaultValues = {
  customUsersTableValues: 2,
  noOfPhoneField: 3,
  defaultDataTableByTruckers: [1, 3, 5],
  defaultDataTableByLoaders: [3, 5],
  hasCustomTable: ['drivers', 'employees', 'branches'],
  status: 'Status',
  actions: 'actions',
  isLedgerTable: 4,
  backspaceKeyCode: 8,
  landline: 'landline',
  mobileLength: 10,
  error: 'error',
  tyres: '12',
  capacity: '1',
  loadingDate: new Date(),
  adminVisibility: 1,
  userVisibility: 0,
  adminStatus: 'Available',
  loaderTruckerStatus: 'In process',
  freightMethod: 'per_ton',
  selectedTypeDate: 4,
  confirmationLink: 'Confirmation Link',
  smsSentReport: 'SMS Sent Report',
}

export const offerAndLoadSubItems = {
  notes: 'notes',
  industry: 'industry',
  product: 'product',
  conditions: 'conditions',
  routes: 'routes',
  freightDetails: 'fr_pr_or',
  freightLoader: 'FR + PR ',
  loadingPoints: 'loading_points',
  LoadingMobileNo: 'loadingMobileNo',
  UnloadingMobileNo: 'unloadingMobileNo',
  unloadingPoints: 'unloading_points',
  loadingCharges: 'loading_charges',
  unloadingCharges: 'unloading_charges',
  placeName: 'area',
  businessArea: 'business_area',
  cluster: 'clusters',
  area: 'area',
  remark: 'remark',
  ownerNo: 'ownerNo',
  driverNo: 'driverNo',
  alternativeNo: 'alternativeNo',
  materials: 'materials',
  capacity: 'capacity',
  loadCapacity: 'loadCapacity',
}

export const loadPaymentInfo = {
  freight: 'Freight',
  pr: 'PR',
  or: 'OR',
  amount: 'Amount',
  amountPayment: 'AmountPayment',
  units: 'Units',
  tons: 'Tons',
  perTon: 'per_ton',
  perUnit: 'per_unit',
  totalFreight: 'total_freight',
  freightMethod: 'freight_method',
  perUnitQuantity: 'per_unit_quantity',
  freightPrAmount: 'freight_or_amount',
  totalAmount: 'totalAmount',
  freightMethodTitle: 'Freight Method',
  freightAmount: 'freight_amount',
  prAmount: 'pr_amount',
  orAmount: 'or_amount',
  totalAmountTitle: 'Total Amount',

}

export const statusChipColor: any = {
  'In process': 'info',
  'Available': 'success',
  'Confirmed': 'success',
  'Expiring': 'warning',
  'Cancelled': 'error',
  'Expired': 'error',
  'Completed': 'purple',
  'Pending': 'warning',
  'Booked': 'process-blue',
  'captured': 'success',
  'failed': 'error',
  'created': 'warning',
}

export const statusValue: any = {
  cancelled: 'Cancelled',
  expired: 'Expired',
  expiring: 'Expiring',
  available: 'Available',
  inProcess: 'In process',
}

export const acceptDocFiles = 'image/png, image/jpg, images/jpeg, application/pdf'

export const getCapacityFromFreightMethod = (data: any) => {
  const mapping: any = {
    total_freight: truckTyreCapacity.find(item => item.id === data?.capacity?.toString())?.value,
    per_ton: `${data?.total_load_weight} tons`,
    per_unit: `${data?.per_unit_quantity} units`,
  }

  return mapping?.[data?.freight_method]
}

export const displayLoadsList = (col: string, data: any) => {
  if (col === 'loaders_place_name')
    return data[col] !== null ? extractCityState(data?.[col]) : ''
  if (col === 'loading_place_name')
    return data[col] !== null ? data?.[col]?.split(',').at(0) : ''
  if (col === 'load_industry')
    return data[col] || data.load_material_industry?.at(0)?.industry?.name
  if (col === 'freight_method')
    return loadsFreight.find((i: { value: string }) => i.value === data[col])?.text
  if (col === 'capacity')
    return getCapacityFromFreightMethod(data)
  if (col === 'is_public')
    return visibility.find(item => item.value === data[col]?.toString())?.text
  if (col === 'fr_pr_or')
    return `${getFormattedAmount(Number(data.freight_amount))} + ${getFormattedAmount(Number(data.pr_amount))} + ${getFormattedAmount(Number(data.or_amount))}`
  if (col === 'delivery')
    return `${getFormattedAmount(Number(data.advance_amount))} + ${getFormattedAmount(Number(data.to_pay_amount))} + ${getFormattedAmount(Number(data.pod_amount))}`
  if (col === 'clusters_place_name')
    return data[col] !== null ? (`${extractCityState(data?.clusters_place_name)} ${(isAdmin.value && data?.cluster_distance) ? `(${data?.cluster_distance})` : ''}`) : ''
  if (col === 'businessCluster')
    return data?.loader?.cluster?.place_name ? extractCityState(data?.loader?.cluster?.place_name) : ''

  return data[col]
}

const getBusinessName = document => {
  const mapping = {
    loader: document?.loader_business_name,
    trucker: document?.trucker_business_name,
    employee: document?.employee_loader_business_name,
    driver: document?.driver_truckers_business_name,
    branch: document?.branch_business_name,
    truck: document?.truck_trucker_business_name,
    bank: document?.bank_business_name,
    confirmation: document?.confirmation_business_name,
    pod: document?.podDocuments_business_name,
  }

  return mapping?.[document?.type] || ''
}

const getBusinessId = (document: any) => {
  const mapping: any = {
    loader: document?.loader_id,
    trucker: document?.trucker_id,
    confirmation: 'N/A',
    pod: 'N/A',
  }

  return mapping?.[document?.type] || document?.documentable_id
}

export const displayDocumentsList = (key: string, document: any, isDocumentsListing = true) => {
  const keyMap = {
    'type': capitalized(isDocumentsListing ? document?.type : document?.type?.type),
    'is_primary': isValue(isDocumentsListing ? document?.is_primary : document?.type?.is_primary),
    'is_public': isConfidential(isDocumentsListing ? document?.is_public : document?.type?.is_public),
    'has_expiry': isValue(isDocumentsListing ? document?.has_expiry : document?.type?.has_expiry),
    'owner.confirmation.formatted_id': document?.confirmation_formatted_id ? document?.confirmation_formatted_id : 'N/A',
    'valid_from': document?.valid_from ? formatDateWithoutTime(document?.valid_from) : '',
    'valid_to': document?.valid_to ? formatDateWithoutTime(document?.valid_to) : '',
    'business_name': getBusinessName(document),
    'business_id': getBusinessId(document),
  }

  return keyMap[key] || document[key]
}

export const displayOffersList = (col: string, data: any) => {
  if (col === 'place_name')
    return data?.trucker?.[col]
  if (col === 'trading_name')
    return data?.trucker?.[col]
  if (col === 'fullname' || col === 'phone')
    return data?.trucker?.user[col]
  if (col === 'primary_fullname')
    return data?.primary?.at(0)?.fullname || ''
  if (col === 'driver_fullname')
    return data?.driver?.at(0)?.fullname || ''
  if (col === 'driver_mobiles')
    return data?.driver?.at(0)?.mobiles?.at(0)?.value || ''
  if (col === 'alternative_fullname')
    return data?.alternative?.at(0)?.fullname || ''
  if (col === 'alternative_mobiles')
    return data?.alternative?.at(0)?.mobiles?.at(0)?.value || ''
  if (col === 'conditions')
    return data[col]?.at(0)?.name || ''
  if (col === 'is_public')
    return offerOrLoadVisibility.find(item => item.value === data[col])?.text
  if (col === 'capacity')
    return truckTyreCapacity.find(item => item.id === data[col]?.toString())?.value
  if (col === 'tyres')
    return `${truckTyreCapacity.find(item => item.tyre === data[col])?.tyreTitle} / ${truckTyreCapacity.find(item => item.id === data?.capacity?.toString())?.value}`
  if (col === 'businessCluster')
    return data?.trucker?.cluster?.place_name ? extractCityState(data?.trucker?.cluster?.place_name) : ''

  return data[col]
}

export const displayRowValue = (subItem: { value: string; subValue: string }, data: any) => {
  if (subItem.value === 'trucker')
    return data[subItem.value]?.place_name
  if (subItem.value === 'trading_name')
    return data.trucker?.trading_name
  if (subItem.value === 'freight_method')
    return loadsFreight.find((i: { value: string }) => i.value === data[subItem.value])?.text
  if (subItem.value === 'capacity')
    return truckTyreCapacity.find(item => item.id === data?.[subItem.value]?.toString())?.value
  if (subItem.value === 'is_public')
    return data[subItem.value] ? 'Public' : 'Private'
  if (subItem.subValue === 'mobiles')

    return data[subItem.value]?.at(0)?.[subItem?.subValue]?.at(0)?.value
  if (subItem.value === 'place_name')
    return data.cluster?.place_name
  if (subItem.subValue)
    return data[subItem.value]?.at(0)?.[subItem?.subValue]

  return data[subItem.value]
}

export const OfferOrLoadColumnName = {
  fr_pr_or: 'fr_pr_or',
  adv_pay_pod: 'adv_pay_pod',
  trucker: 'trucker',
  trading_name: 'trading_name',
  freight_method: 'freight_method',
  capacity: 'capacity',
  is_public: 'is_public',
  mobiles: 'mobiles',
  place_name: 'place_name',
  loader: 'loader',
}

export const displayOfferOrLoadProfileValue = (subItem: { value: string; subValue: string }, data: any) => {
  if (subItem.value === OfferOrLoadColumnName.fr_pr_or)
    return `${getFormattedAmount(data?.load_payment_info?.freight_amount ?? 0)} + ${getFormattedAmount(data?.load_payment_info?.pr_amount ?? 0)} + ${getFormattedAmount(data?.load_payment_info?.or_amount ?? 0)}`
  if (subItem.value === OfferOrLoadColumnName.adv_pay_pod)
    return `${getFormattedAmount(data?.load_payment_info?.advance_amount ?? 0)} + ${getFormattedAmount(data?.load_payment_info?.to_pay_amount ?? 0)} + ${getFormattedAmount(data?.load_payment_info?.pod_amount ?? 0)}`
  if (subItem.value === OfferOrLoadColumnName.trucker)
    return data[subItem.value]?.place_name
  if (subItem.value === OfferOrLoadColumnName.trading_name)
    return data.trucker?.trading_name
  if (subItem.value === OfferOrLoadColumnName.freight_method) {
    return loadsFreight.find((i: { value: string }) => {
      return i.value === data?.load_payment_info?.[subItem.value]
    })?.text
  }
  if (subItem.value === OfferOrLoadColumnName.capacity) {
    return getCapacityFromFreightMethod({
      capacity: data?.capacity,
      ...data?.load_payment_info,
    })
  }
  if (subItem.value === OfferOrLoadColumnName.is_public)
    return visibility.find(item => item.value === data[subItem.value]?.toString())?.text
  if (subItem.subValue === OfferOrLoadColumnName.mobiles)
    return data[subItem.value]?.at(0)?.[subItem?.subValue]?.at(0)?.value
  if (subItem.value === OfferOrLoadColumnName.place_name)
    return data.cluster?.place_name
  if (subItem.value === 'cluster')
    return `${extractCityState(data.cluster?.place_name)} ${(isAdmin.value && data?.cluster_distance) ? `(${data?.cluster_distance})` : ''}`
  if (subItem.value === OfferOrLoadColumnName.loader)
    return data[subItem.value]?.[subItem?.subValue]
  if (subItem.value === 'tyres')
    return `${truckTyreCapacity.find(item => item.tyre === data[subItem.value])?.tyreTitle} / ${truckTyreCapacity.find(item => item.id === data?.capacity?.toString())?.value}`
  if (subItem.value === 'businessCluster')
    return data?.loader?.cluster?.place_name ? extractCityState(data?.loader?.cluster?.place_name) : ''
  if (subItem.value === 'businessOfferCluster')
    return data?.trucker?.cluster?.place_name ? extractCityState(data?.trucker?.cluster?.place_name) : ''
  if (subItem.subValue)
    return data[subItem.value]?.at(0)?.[subItem?.subValue]

  return data[subItem.value]
}

export const displayUsersSubtypesValue = computed(() => (data: any, val: string) => {
  if (val === 'is_docs_verified')
    return data[val] ? 'Verified' : 'Unverified'
  if (val === 'is_active')
    return data[val] ? 'Active' : 'Inactive'

  return Array.isArray(data[val]) ? data[val].at(0)?.value : data[val]
})

export const usersListType = {
  payer: 'Payer',
  preferredMaterials: 'Pref. Materials / Products',
  preferredRoutes: 'Preferred Routes',
  products: 'Materials / Products',
  conditions: 'Conditions',
  industry: 'Industry',
  cluster: 'Offer Cluster',
  truckCluster: 'Truck Available Cluster',
  availableArea: 'Available Area',
  driverNo: 'Driver Mobile No.',
  ownerNo: 'Owner Mobile No.',
  alternativeName: 'Alt Name',
  alternativeNo: 'Alt Mobile No.',
  primaryName: 'Pri Name',
  placeName: 'place_name',
  address: 'address',
  businessName: 'Business Name',
  charger: 'Hamali Charges',
  remark: 'Remarks',
  area: 'Area',
  loadingPoints: 'Loading Points',
  unloadingPoints: 'Unloading Points',
  UnloadingMobileNo: 'Unloading Mobile No.',
  LoadingMobileNo: 'Loading Mobile No.',
  freightAdmin: 'FR + PR + OR',
  freightLoader: 'FR + PR',
  freightTrucker: 'FR + Crossing',
  loadingCharges: 'Loading Charges',
  loadingCluster: 'Loading Cluster',
  unloadingCharges: 'Unloading Charges',
  loading_points: 'loadingPoints',
  materials: 'materials',
  unloading_points: 'unloadingPoints',
  business_area: 'Business Area',
  email: 'email',
  website: 'Website',
  mobileNo: 'Preferred Contact No.',
  landlineNo: 'Landline No.',
  mobile: 'Mobile No.',
  mobiles: 'mobiles',
  landlines: 'landlines',
  primaryMobiles: 'primary_mobiles',
  altMobileNo: 'Alternate Mobile No.',
  designation: 'designation',
  primaryMobile: 'Pri Mobile No.',
  charges: 'charges',
  placeNameArea: 'placeName',
  createdDate: 'Created Timestamp',
  updatedDate: 'Updated Timestamp',
  lwh: 'lwh',
  material: 'Material',
  Email: 'Email Address',
  Address: 'Address',
  notes: 'Notes',
  truckNo: 'Truck No.',
  leadDistance: 'Lead Distance',
  totalDistance: 'Total Distance',
  targetedRoute: 'Targeted Route',
  offerCluster: 'Offer Cluster',
  confirmationLeadDistance: 'Lead Distance / Truck Available Area',
  truckAvailableArea: 'Truck Available Area',
  truckAvailableCluster: 'Truck Available Cluster',
}

export const messageModuleType = {
  offerSuggestion: 'offerSuggestion',
  loadSuggestion: 'loadSuggestion',
}

export const confirmationHistoryColumns = [
  { name: 'SR No.', value: 'index' },
  { name: 'CNF ID', value: 'formattedId' },
  { name: 'CNF Date', value: 'confirmedAt' },
  { name: 'Loader Business Name', value: 'loaderBusinessName' },
  { name: 'Trucker Business Name', value: 'truckerBusinessName' },
  { name: 'Loading Point', value: 'loadingPoints' },
  { name: 'Unloading Point', value: 'unloadingPoints' },
  { name: 'Materials', value: 'materials' },
  { name: 'Truck No.', value: 'truckNumber' },
  { name: 'Capacity', value: 'capacity' },
  { name: 'FR', value: 'freightAmount' },
  { name: 'PR', value: 'prAmount' },
  { name: 'OR', value: 'orAmount' },
]

export const loadingColumns = [
  { name: 'Business Name', value: 'business_name' },
  { name: 'Loading Point', value: 'place_name' },
  { name: 'Mobile No.', value: 'preferred_contact' },
  { name: 'Charges', value: 'charges' },
]

export const unloadingColumns = [
  { name: 'Business Name', value: 'business_name' },
  { name: 'Unloading Point', value: 'place_name' },
  { name: 'Mobile No.', value: 'preferred_contact' },
  { name: 'Charges', value: 'charges' },
]

export const loadingUnloadingPoint = ['loading_points', 'unloading_points']

export const paymentModeColumns = [
  { name: 'UTR No.', value: 'utr_no' },
  { name: 'Amount', value: 'amount' },
  { name: 'Paid To', value: 'paid_to' },
  { name: 'Date', value: 'date' },
  { name: 'Payment Mode', value: 'payment_mode' },
  { name: 'Receipt', value: 'receipt' },
]

export const smsSentReportColumns = [
  { title: 'Date & Time', key: 'createdAt' },
  { title: 'Mobile No.', key: 'mobile' },
  { title: 'Template', key: 'template' },
  { title: 'Template Name', key: 'template.name' },
  { title: 'SMS Status', key: 'status' },
]

export const waSentReportColumns = [
  { title: 'Date & Time', key: 'createdAt' },
  { title: 'Mobile No.', key: 'mobile' },
  { title: 'Template', key: 'template' },
  { title: 'Template Name', key: 'template.name' },
]

export const smsCampaign = [
  { title: 'Campaign', key: 'campaignId' },
  { title: 'Date & Time', key: 'createdAt' },
  { title: 'Campaign Status', key: 'status' },
  { title: 'Total Count', key: 'messagesSent' },
  { title: 'Message Type', key: 'messageType' },
]

export const routeOrClusterHeaderValue = ['pending', 'in_process', 'available', 'expiring', 'expired', 'cancelled', 'confirmed', 'completed']

export const hasViewByTypeForLatestConfirmation = ['loader_info', 'trucker_info']

export const userTypeForConfirmation = {
  loader: 1,
  trucker: 2,
}

export const usersSearchFields: ISearchUsers = reactive({
  id: '',
  businessName: '',
  placeName: '',
  clusterPlaceName: null,
  fullname: '',
  phone: '',
  tradingName: '',
  materials: null,
  industry: null,
  isActive: null,
  verified: null,
  isDocsVerified: null,
  userType: null,
  notificationTitle: '',
  landlines: '',
  email: '',
})

export const resetSearchFields = { ...usersSearchFields }

export const transactionStatus = [
  { text: i18n.global.t('Success'), value: 'captured' },
  { text: i18n.global.t('Pending'), value: 'created' },
  { text: i18n.global.t('Failed'), value: 'failed' },
]

export const transactionsSearchFields = reactive({
  confirmationId: '',
  transactionId: '',
  description: '',
  payment: '',
  fromDate: '',
  toDate: '',
  status: null,
})

export const transactionsTableOptions = reactive({
  page: 1,
  limit: 10,
  sortField: '',
  sortOrder: '',
})
