import { Ability } from '@casl/ability'
import type { UserAbility } from './AppAbility'
import { getEncryptedItem } from '@/helpers'

export const initialAbility: UserAbility[] = [
  {
    action: 'read',
    subject: 'Auth',
  },
]

//  Read ability from localStorage
// 👉 Handles auto fetching previous abilities if already logged in user
// ℹ️ You can update this if you store user abilities to more secure place
// ❗ Anyone can update localStorage so be careful and please update this
const stringifiedUserAbilities = getEncryptedItem('userAbilities')
let existingAbility = stringifiedUserAbilities || []
existingAbility = [...existingAbility, ...initialAbility]
export default new Ability(existingAbility)
