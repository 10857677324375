import { encryptStorage } from '@/utils/storage'

export const set = (key: string, value: string) => {
  localStorage.setItem(key, value)
}

export const get = (key: string): string | null => {
  return localStorage.getItem(key)
}

export const remove = (key: string) => {
  localStorage.removeItem(key)
}

export const clearAll = () => {
  localStorage.clear()
}

export const setEncryptedItem = (key: string, value: string) => {
  encryptStorage.setItem(key, value)
}

export const getEncryptedItem = (key: string) => encryptStorage.getItem(key)

export const removeEncryptedItem = (key: string) => {
  encryptStorage.removeItem(key)
}
